const state = {
  keycloak: null,
}

const mutations =  {
  SET_KEYCLOAK(state, keycloak) {
    state.keycloak = keycloak
  }
}

const getters = {
  keycloak: (state) => state.keycloak,
}

const actions = {
  logout({ commit, state }, payload = {}) {
    state.keycloak.logout(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
