<template>
  <md-card class="md-card-profile" v-if="mall">
    <div class="md-card-avatar">
      <img class="img" :src="mall.logo" />
    </div>
    <md-card-content>
      <h6 class="category text-gray">店铺信息</h6>
      <h4 class="card-title">{{ mall.name }}</h4>
      <p class="card-description">
        <ul>
          <li>店铺描述： {{ mall.description }}</li>
          <li>授权到期： {{ mall.expire | expire }}</li>
          <li>创建时间： {{ mall.createdAt | moment }}</li>
          <li>最近登陆： {{ mall.updatedAt | moment }}</li>
          <li>版本: {{ mall.contractType }}</li>
        </ul>

      </p>
    </md-card-content>
  </md-card>
</template>

<script>
import moment from "moment";
export default {
  name: "user-profile-card",
  props: {
    cardUserImage: {
      type: String,
      default: process.env.VUE_APP_BASE_URL + "/img/faces/marc.jpg",
    },
    mall: {
      type: Object,
    },
  },
  filters: {
    expire: function (unixTime) {
      return moment.unix(unixTime).format("YYYY-MM-DD HH:mm:ss");
    },
    moment: function (dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {};
  },
  methods: {
    getColorButton: (buttonColor) => "md-" + buttonColor + "",
  },
};
</script>
