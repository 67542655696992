<template>
  <div class="user">
    <div class="photo" v-if="mall">
      <img :src="logo" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        v-if="mall"
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>
          {{ mall.name }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li class="expire_info">
                <div>
                  <span>授权有效期: {{ expire }}</span> <br />
                  <span>版本: {{ mall?.contractType }}</span> <br/>
                  <span>每天可搬家次数: {{ mall?.limit }}</span>
                </div>
              </li>
              <li>
                <a @click="logout">
                  <md-icon>logout</md-icon>
                  <span class="sidebar-normal">退出</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      isClosed: false,
      title: "",
      avatar: process.env.VUE_APP_BASE_URL + "/img/faces/marc.jpg",
    };
  },

  computed: {
    isActive() {
      // 账户信息高亮
      if (this.$route && this.$route.path && this.$route.name == "账户") {
        return true;
      }

      return false;
    },
    ...mapState({
      mall: (state) => state.mall.current,
    }),
    expire () {
      if (this.mall) {
        return moment.unix(this.mall.expire).format('YYYY.MM.DD HH:mm:ss')
      }

      return ''
    },
    logo () {
      if (this.mall) {
        return this.mall.logo.replace('http://', 'https://')
      }

      return ''
    }
  },

  async created() {
    this.$store.watch(
      () => this.$store.getters["mall/current"],
      (mall) => {
        this.title = mall.name;
        this.avatar = mall.logo;
      }
    );
    // await this.$store.dispatch('mall/me')
  },

  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    logout() {
      this.$store.dispatch("app/logout", {
        redirectUri: 'https://whaleniu.cn'
      });
    },
  },
  mounted() {
    this.$store.dispatch("mall/setCurrentMall");
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
li.expire_info div {
  margin: 0px 15px 0;
  padding: 8px 10px;
}

li.expire_info div span{
  color: white;
  font-size: 10pt;
}

.sidebar-mini li.expire_info {
  display: none;
}
</style>
