<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>bookmarks</md-icon>
      </div>

      <h4 class="title">模版列表</h4>
    </md-card-header>
    <!-- <md-card-actions md-alignment="right">
      <md-button class="md-primary md-dense" @click="createNew">创建新模版</md-button>
    </md-card-actions> -->
    <md-card-content>
      <md-dialog-confirm
        :md-active.sync="active"
        md-title="确定要删除模版吗? 删除后无法恢复！"
        :md-content="
          '当前您正要删除模版: &nbsp;&nbsp;&nbsp; <strong>' +
          this.selected?.name +
          '</strong>'
        "
        md-confirm-text="确定"
        md-cancel-text="取消"
        @md-confirm="onConfirm"
      />

      <md-table
        ref="table"
        v-if="!loading"
        @md-selected="onSelect"
        :value="templates"
        :md-selected-value="mdSelectedItem"
        class="paginated-table table-striped table-hover"
      >
        <md-table-toolbar>
          <md-button class="md-primary md-dense" @click="createNewTemplate"> 创建新模版 </md-button>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="您还没有模版"
          :md-description="`点击下面的按钮去创建一个新的模版`"
        >
          <md-button class="md-primary md-raised"  @click="createNewTemplate">创建新模版</md-button>
        </md-table-empty-state>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="getClass(item)"
          md-selectable="single"
        >
          <md-table-cell md-label="模版名称">{{ item.name }}</md-table-cell>
          <md-table-cell md-label="创建时间">{{
            item.createdAt | moment
          }}</md-table-cell>
          <md-table-cell md-label="更新时间">{{
            item.updatedAt | moment
          }}</md-table-cell>

          <md-table-cell md-label="操作" class="operation-area">
            <md-button
              v-show="showActions(item)"
              class="md-just-icon md-danger operation-button"
              @click.stop="deleteTemplateConfirm"
            >
              <md-icon>delete</md-icon>
              <md-tooltip>删除模版</md-tooltip>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>

<script>
import moment from "moment";
import boltService from "@/store/services/bolt-service";

export default {
  components: {},

  data: () => ({
    templates: [],
    loading: false,
    mdSelectedItem: null,
    active: false,
    selected: null,
  }),

  created() {
    this.getList();
    // .then(() => {
    //   if (this.$route.params?.id) {
    //     let templateId = this.$route.params.id;
    //     this.mdSelectedItem = this.templates.find(template => template.id == templateId)
    //   }
    // });

    // this.$watch(
    //   () => this.$route,
    //   (to, previous) => {
    //     // react to route changes...
    //     // this.templateId = toParams.id;
    //     if (to.name == "上货模版") {
    //       // this.getList()
    //       this.$set(this, 'mdSelectedItem', [])
    //     }

    //   }
    // )
  },

  filters: {
    moment: function (dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    async getList(clear = false) {
      this.loading = true;
      this.templates = await boltService.getProductTemplates();

      if (clear) {
        this.mdSelectedItem = this.mdSelectedItem == null ? [] : null;
      }

      this.loading = false;
    },
    getClass: ({ id }) => ({
      "md-primary": true,
      // 'md-accent': true
    }),
    onSelect(item) {
      this.selected = item;
      this.$emit("selected", item);
    },
    createNewTemplate() {
      if (this.selected) {
        this.mdSelectedItem = this.mdSelectedItem == null ? [] : null;
      }

      this.$emit("create-new-template");

      // this.$emit('create-new-template')
    },
    deleteTemplateConfirm() {
      this.active = true;
    },
    showActions(item) {
      if (!this.selected) {
        return false;
      }

      return this.selected.id == item.id;
    },
    onConfirm() {
      boltService
        .deleteProductTemplate(this.selected.id)
        .then(() => {
          this.$store.dispatch("alerts/success", "删除成功");
          // this.getList();

          this.templates = this.templates.filter(
            (template) => template.id != this.selected.id
          );
          this.selected = null;
          this.$emit("deleted", null);
          // this.$router.push({
          //   name: "上货模版"
          // });
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.response.data?.error);
        });
    },
  },
  beforeDestroy() {},
};
</script>

<style>
td.operation-area {
  width: 80px !important;
}

.md-button.operation-button {
  width: 27px;
  height: 27px;
  min-width: 27px;
  line-height: 27px;
  padding: 5px;
  margin: 0;

  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
