<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" @click="toggleDropDown">
        <i class="fas fa-edit fa-cog fa-lg"></i>
        <br />
        <small class="md-caption" style="color: white">意见反馈</small>
      </a>
      <form novalidate class="md-layout" @submit.prevent="validateMessage">
        <ul class="dropdown-menu" :class="{ show: isOpen }">
          <li class="header-title">意见反馈</li>
          <li style="height: auto; width: 100%">
            <!-- <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Users</div>
        </md-card-header>

        <md-card-content> -->
            <md-field :class="getValidationClass('messageContent')">
              <label for="message-content"
                >Hi，请输入您对鲸牛搬家助手的问题反馈或建议吧～</label
              >
              <md-textarea
                ref="messageContent"
                name="message-content"
                id="message-content"
                mdAutogrow
                maxlength="1000"
                md-counter
                required
                v-model="form.messageContent"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.messageContent.required"
                >请输入您的问题反馈或建议～</span
              >
              <span
                class="md-error"
                v-else-if="!$v.form.messageContent.minlength"
                >问题反馈或建议不能少于3个字</span
              >
            </md-field>

            <!-- <md-button type="submit" class="md-primary" :disabled="sending">Create user</md-button> -->
            <!-- </md-card-content> -->
            <md-progress-bar md-mode="indeterminate" v-if="sending" />
            <!--

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Create user</md-button>
        </md-card-actions>
      </md-card> -->

            <md-snackbar :md-active.sync="userSaved"
              >The user {{ lastUser }} was saved with success!</md-snackbar
            >
          </li>

          <li class="header-title d-flex justify-content-center">
            谢谢您的意见与建议～
          </li>

          <li class="button-container">
            <md-button type="submit" class="md-dense md-primary" :disabled="sending"
              >提交</md-button
            >
            <a id="clearBottom" @click="clearForm">取消</a>

          </li>
        </ul>
      </form>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      isOpen: false,
      form: {
        messageContent: null,
      },
      userSaved: false,
      sending: false,
      lastUser: null,
    };
  },
  validations: {
    form: {
      messageContent: {
        required,
        // minLength: minLength(3),
        maxLength: maxLength(1000),
      },
    },
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        // focus on message content
        this.$nextTick(() => {
          this.$refs.messageContent.$el.focus();
        });
      }
    },
    closeDropDown() {
      this.isOpen = false;
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    clearForm () {
        this.$v.$reset()
        this.form.messageContent = null
        this.toggleDropDown()
      },

    validateMessage() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveMessage();
      }
    },

    async saveMessage() {
      this.sending = true;

      try {
        await this.$http.post("/messages", { message: this.form.messageContent });
        this.sending = false
        this.clearForm()
        this.$store.dispatch("alerts/success", '提交成功，我们会尽快处理，感谢您的意见和建议～');
      } catch (e) {
        this.sending = false
        this.$store.dispatch("alerts/error", "系统繁忙，请稍后再试～");
      }
    },
  },
};
</script>
<style>
.centered-row {
  display: flex;
  height: 100%;
  align-items: center;
}

.button-container .btn {
  margin-right: 10px;
}

.centered-buttons {
  display: flex;
  justify-content: center;
}

.fixed-plugin .dropdown.show .dropdown-menu {
  width: 500px;
}

#clearBottom {
  display: inline-block;
  height: 32px;
  margin-top:4px;
}

#clearBottom:hover {
  background-color: white !important;
  color: black !important;
  text-decoration: underline !important;
}
</style>
