export default class Category {
  constructor(id, name, leaf = false) {
    this.id = id;
    this.name = name;
    this.leaf = leaf;
  }

  toString() {
    if (!this.name) {
      return "";
    }
    return this.name;
  }

  toLowerCase() {
    if (!this.name) {
      return "";
    }
    return this.name.toLowerCase();
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      leaf: this.leaf,
    }
  }
}

export class AutoCompleteItem {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  toString() {
    return this.name;
  }

  toLowerCase() {
    return this.name.toLowerCase();
  }
}

