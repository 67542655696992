import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'

// Help pages
import MovePageVue from '@/pages/Dashboard/Helper/MovePage.vue'
import InfoPageVue from '@/pages/Dashboard/Helper/InfoPage.vue'
import ProductUploadPage from '@/pages/Dashboard/upload/ProductUploadPage.vue'
import TemplatesPage from '@/pages/Dashboard/upload/TemplatesPage.vue'
import TemplateEdit from '@/pages/Dashboard/upload/TemplateEdit.vue'

// Admin pages
import HistoryPage from '@/pages/Dashboard/Admin/HistoryPage.vue'
import ShopProfilePage from '@/pages/Dashboard/Admin/ShopProfilePage.vue'

// Group pages
import GroupListPage from '@/pages/Dashboard/Groups/List.vue'

//import middleware
import permission from '@/middleware/permission'
import loadTemplate from '@/middleware/loadTemplate'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard',
  //   name: 'Home',
  // },
  {
    path: "/",
    redirect: "/assistant/workspace",
    name: "Home",
  },
  {
    path: "/admin",
    component: DashboardLayout,
    meta: { middleware: permission },
    children: [
      {
        path: "workspace",
        name: "系统工作台",
        components: { default: HistoryPage },
        meta: { middleware: permission },
      },
      {
        path: "shop-profile",
        name: "店铺信息",
        components: { default: ShopProfilePage },
      },
    ],
  },
  {
    path: "/assistant",
    component: DashboardLayout,
    meta: { middleware: permission },
    children: [
      {
        path: "workspace",
        name: "工作台",
        components: { default: MovePageVue },
        meta: { middleware: permission },
      },
      {
        path: "info",
        name: "使用说明",
        components: { default: InfoPageVue },
      },
      {
        path: "bolt",
        name: "快速上货",
        components: { default: ProductUploadPage },
        meta: { middleware: permission, permission: "product_upload" },
      },
      {
        path: "templates",
        name: "上货模版",
        components: { default: TemplatesPage },
        meta: { middleware: permission, permission: "product_upload" },
        // children: [
        //   {
        //     path: 'update-template/:id',
        //     name: '模版编辑',
        //     components: { default: TemplateEdit },
        //     meta: { middleware: [permission, loadTemplate], permission: 'product_upload' },
        //   }
        // ]
      },

      // {
      //   path: 'create-template',
      //   name: '新建模版',
      //   components: { default: TemplateEdit },
      //   meta: { middleware: permission, permission: 'product_upload' },
      // },
      // {
      //   path: 'update-template/:id',
      //   name: '修改模版',
      //   components: { default: TemplateEdit },
      //   meta: { middleware: permission, permission: 'product_upload' },
      // },
      {
        path: "*",
        redirect: "/assistant/workspace",
      },
    ],
  },
  {
    path: "/groups",
    component: DashboardLayout,
    meta: { middleware: permission },
    children: [
      {
        path: "list",
        name: "群组列表",
        components: { default: GroupListPage },
        meta: { middleware: permission },
        // children: [
        //   {
        //     path: 'update-template/:id',
        //     name: '模版编辑',
        //     components: { default: TemplateEdit },
        //     meta: { middleware: [permission, loadTemplate], permission: 'product_upload' },
        //   }
        // ]
      },
    ],
  },
  {
    path: "*",
    redirect: "/assistant/workspace",
  },
  // helperMenu,
  // componentsMenu,
];

export default routes
