<template>
  <md-autocomplete
    v-model="selected"
    :md-options="items"
    required
  >
  <slot />
  </md-autocomplete>
</template>
<script>
import shopService from '@/store/services/shop-service.js'
import Category from "@/pdd/PddCategoryObject.js"

export default {
  props: {
    value: { type: Object, required: false, default: null },
    parent: { type: Object, required: false, default: function() {
      return new Category(0, '', false)
    }},
    mdInputPlaceholder: [String, Number]
  },
  data: () => ({
    items: [],
    selected: null,
  }),
  watch: {
    parent: function (newVal, oldVal) {
      this.getCategories(newVal.id)
    },
  },
  created () {
    this.getCategories(this.parent.id).finally(() => {
      this.selected = this.value
      this.$watch('selected', function(newVal, oldVal) {
        // 如果newVal 是string
        if (typeof newVal === 'string') {
          newVal = this.items.find(item => item.name === newVal)
        }

        if (oldVal && newVal && newVal.id === oldVal.id) {
          return
        }

        this.$emit('input', newVal)
      })
    })
  },
  mounted () {

  },
  methods: {
    getCategories: async function (parent = 0) {
      return shopService.getShopCategories(parent).then(data => {
        this.items = data.map(category => {
          return new Category(category.cat_id, category.cat_name, category.leaf)
        })
      })
    }
  },
  beforeDestroy () {
    this.$emit('input', null)
  }
}
</script>
