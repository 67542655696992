export const Select = {
  props: {
    value: {
      type: String,
      required: false,
    }, //默认值
  },
  data: function() {
    return {
      dataReady: false,
      options: [],
      action: '',
    }
  },

  computed: {},
  methods: {
    emitValue(value) {
      if (value == '') {
        this.$emit('input', null)
      } else {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
    this.getOptions().then((options) => {
      this.options = options
      this.dataReady = true
    })
  },
}
