<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <system-notification></system-notification>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'لوحة القيادةة',
            icon: 'dashboard',
            path: '/dashboard',
          }"
        />
        <!-- <sidebar-item
          v-else
          :link="{ name: '首页', icon: 'dashboard', path: '/dashboard' }"
        /> -->
        <sidebar-item
          :link="{ name: '系统管理', icon: 'settings', path: '/admin/workspace' }" permission="admin"
        >
        </sidebar-item>

        <sidebar-item
          :link="{ name: '搬家助手', icon: 'local_shipping', path: '/assistant/workspace' }"
        >
          <!-- <sidebar-item :link="{ name: '快捷搬家', path: '/helper' }" /> -->
          <!-- <sidebar-item
            :link="{
              name: '下载图片',
              path: '/examples/user-management/list-users',
            }"
          />
          <sidebar-item
            :link="{
              name: '下载视频',
              path: '/examples/user-management/list-users',
            }"
          />
          <sidebar-item
            :link="{
              name: '商品同步',
              path: '/examples/user-management/list-users',
            }"
          /> -->
        </sidebar-item>

         <sidebar-item
          :link="{ name: '群组管理', icon: 'groups', path: '/groups/list' }"
        ></sidebar-item>

         <sidebar-item
          :link="{ name: '使用说明', icon: 'help_outline', path: '/assistant/info' }"
        ></sidebar-item>

        <sidebar-item
          :link="{ name: '快速上货', icon: 'bolt', path: '/assistant/bolt' }" permission="product_upload"
        ></sidebar-item>

        <sidebar-item
          :link="{ name: '上货模版', icon: 'bookmarks', path: '/assistant/templates' }" permission="product_upload"
        ></sidebar-item>

        <!-- <sidebar-item
          :link="{ name: '商品列表', icon: 'list', path: '/goods/list' }"
        ></sidebar-item> -->

        <!-- <sidebar-item opened :link="{ name: 'Examples (API)', image: image }">
          <sidebar-item
            :link="{ name: '账户信息', path: '/examples/account-profile' }"
          />
          <sidebar-item
            :link="{
              name: 'User Management',
              path: '/examples/user-management/list-users',
            }"
          />
        </sidebar-item> -->

        <!-- <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'الجداول',
            icon: 'content_paste',
            path: '/components/table',
          }"
        /> -->
        <!-- <sidebar-item
          :link="{
            name: 'Table Lists',
            icon: 'content_paste',
            path: '/components/table',
          }"
        />

        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'طباعة',
            icon: 'library_books',
            path: '/components/typography',
          }"
        />
        <sidebar-item
          v-else
          :link="{
            name: 'Typography',
            icon: 'library_books',
            path: '/components/typography',
          }"
        />

        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'الرموز',
            icon: 'bubble_chart',
            path: '/components/icons',
          }"
        />
        <sidebar-item
          v-else
          :link="{
            name: 'Icons',
            icon: 'bubble_chart',
            path: '/components/icons',
          }"
        />

        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'خرائط جوجل',
            icon: 'place',
            path: '/components/maps',
          }"
        />
        <sidebar-item
          v-else
          :link="{ name: 'Maps', icon: 'place', path: '/components/maps' }"
        />

        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{
            name: 'إخطارات',
            icon: 'notifications',
            path: '/components/notifications',
          }"
        />
        <sidebar-item
          v-else
          :link="{
            name: 'Notifications',
            icon: 'notifications',
            path: '/components/notifications',
          }"
        />

        <sidebar-item
          v-if="$route.meta.rtlActive"
          :link="{ name: 'دعم رتل', icon: 'language', path: '/components/rtl' }"
        />
        <sidebar-item
          v-else
          :link="{
            name: 'RTL Support',
            icon: 'language',
            path: '/components/rtl',
          }"
        /> -->
      </template>

      <!-- <div style="width: 100%; position: absolute; bottom: 0; padding: 16px">
        <md-button
          v-if="sidebarMini"
          class="md-button md-danger md-block"
          href="https://www.creative-tim.com/product/vue-material-dashboard-laravel-pro"
          target="_blanck"
        >
          <i class="fas fa-download" style="margin-right: 4px"></i>Upgrade to
          PRO
        </md-button>
      </div> -->
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <message-plugin></message-plugin>

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
    document.getElementsByClassName(className)[0].scrollTop = 0
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList
  let isWindows = navigator.platform.startsWith('Win')
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar('sidebar')
    initScrollbar('sidebar-wrapper')
    initScrollbar('main-panel')

    docClasses.add('perfect-scrollbar-on')
  } else {
    docClasses.add('perfect-scrollbar-off')
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import MobileMenu from './Extra/MobileMenu.vue'
// import FixedPlugin from '../../FixedPlugin.vue'
import UserMenu from './Extra/UserMenu.vue'
import MessagePlugin from '../../MessagePlugin.vue'
import SystemNotification from './SystemNotification.vue'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    // FixedPlugin,
    MobileMenu,
    UserMenu,
    MessagePlugin,
    SystemNotification,
  },
  data() {
    return {
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'green',
      sidebarBackgroundImage:
        process.env.VUE_APP_BASE_URL + '/img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true,
      image: process.env.VUE_APP_BASE_URL + '/img/laravel-vue.svg',
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
  },
  updated() {
    reinitScrollbar()
  },
  mounted() {
    reinitScrollbar()
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
  },
}
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
