import Vue from 'vue'
import Vuex from 'vuex'

import alerts from './modules/alerts-module'
import mall from './modules/mall-module'
import settings from './modules/settings-module'
import app from './modules/app-module'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    mall,
    settings,
    modules,
    app,
  },
})
