import axios from '../../axios/index'

// 商品模版列表
function getProductTemplates() {
  return axios
    .get(`/bolt/templates`)
    .then((response) => response.data)
}

// 商品模版
function getProductTemplate(id) {
  return axios
    .get(`/bolt/templates/${id}`)
    .then((response) => response.data)
}

// 创建模版
function createProductTemplate(template) {
  return axios
    .post(`/bolt/templates`, template)
    .then((response) => response.data)
}

// 更新模版
function updateProductTemplate(id, template) {
  return axios
    .patch(`/bolt/templates/${id}`, template)
    .then((response) => response.data)
}

// 删除模版
function deleteProductTemplate(id) {
  return axios
    .delete(`/bolt/templates/${id}`)
    .then((response) => response.data)
}

// 更新配置
function updateSettings(settings) {
  return axios
    .patch(`/bolt/settings`, settings)
    .then((response) => response.data)
}

// 获取配置
function getSettings() {
  return axios
    .get(`/bolt/settings`)
    .then((response) => response.data)
}

// 创建任务
function createTask(task) {
  return axios
    .post(`/bolt/tasks`, task)
    .then((response) => response.data)
}


export default {
  getProductTemplates,
  getProductTemplate,
  createProductTemplate,
  updateProductTemplate,
  deleteProductTemplate,
  updateSettings,
  getSettings,
  createTask,

}
