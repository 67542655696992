var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive },
  ]},[_c('notifications'),_c('system-notification'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'لوحة القيادةة',
          icon: 'dashboard',
          path: '/dashboard',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: '系统管理', icon: 'settings', path: '/admin/workspace' },"permission":"admin"}}),_c('sidebar-item',{attrs:{"link":{ name: '搬家助手', icon: 'local_shipping', path: '/assistant/workspace' }}}),_c('sidebar-item',{attrs:{"link":{ name: '群组管理', icon: 'groups', path: '/groups/list' }}}),_c('sidebar-item',{attrs:{"link":{ name: '使用说明', icon: 'help_outline', path: '/assistant/info' }}}),_c('sidebar-item',{attrs:{"link":{ name: '快速上货', icon: 'bolt', path: '/assistant/bolt' },"permission":"product_upload"}}),_c('sidebar-item',{attrs:{"link":{ name: '上货模版', icon: 'bookmarks', path: '/assistant/templates' },"permission":"product_upload"}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('message-plugin'),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }