<template>
  <template-form-card :templateId="templateId" :key="templateId"></template-form-card>
</template>

<script>

import TemplateFormCard from '@/pages/Dashboard/User/ShopManagement/TemplateFormCard.vue';


export default {
  components: {
    TemplateFormCard
  },
  data: () => ({
    templateId: null,
  }),
  watch: {
    templateId() {

    }
  },
  methods: {


  },
  created() {
    this.templateId = this.$route.params.id;
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        // react to route changes...
        this.templateId = toParams.id;

      }
    )
  },
  mounted() {
    // this.templateId = this.$route.params.id;
  },
};
</script>

<style>
</style>
