import service from '@/store/services/shop-service'

const state = {
  settings: null,
}

const mutations = {
  SET_RESOURCE: (state, settings) => {
    state.settings = settings
  },
}

const actions = {
  get({ commit, dispatch }, params) {
    return service.getSettings(params).then((settings) => {
      commit('SET_RESOURCE', settings)
      return settings
    })
  },

  update({ commit, dispatch }, settings) {
    return service
      .updateSettings(settings)
      .then((response) => {
        // if (response.success) {
          commit('SET_RESOURCE', settings)

          // dispatch('me').then(() => {
          // dispatch('alerts/success', '配置保存成功.', { root: true })
          // })
        // }
      })
  },
}

const getters = {
  settings: (state) => state.settings,
}

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default profile
