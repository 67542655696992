<template>
  <form novalidate @submit.prevent="validateForm">
    <md-card v-if="settings">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>settings</md-icon>
        </div>
        <h4 class="title">商品设置</h4>
      </md-card-header>
      <md-card-content>
        <md-tabs
          class="md-transparent"
          :md-active-tab="activeTab"
          @md-changed="onTabChange"
        >
          <md-tab id="tab-favorites" md-label="运费模版" md-icon="title">
            <!-- <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <p class="text-muted">商品标题</p>
                <md-radio
                  name="cut-title"
                  v-model="settings.sku.title"
                  :value="choices.cutTitleLeft"
                  >截取前面30个字</md-radio
                >
                <md-radio
                  name="cut-title"
                  v-model="settings.sku.title"
                  :value="choices.cutTitleRight"
                  >截取后面30个字</md-radio
                >
              </div>
            </div> -->
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field
                  :error="
                    $v.settings.service.cost_template_id.$error
                      ? 'tab-favorites'
                      : null
                  "
                  :class="getServiceValidationClass('cost_template_id')"
                >
                  <label for="shipping-template">运费模版</label>
                  <shipping-template
                    name="shipping-template"
                    v-model="settings.service.cost_template_id"
                  ></shipping-template>
                  <span
                    class="md-error"
                    v-if="!$v.settings.service.cost_template_id.required"
                    >请选择运费模版！</span
                  >
                </md-field>
              </div>
            </div>
          </md-tab>

          <!-- <md-tab
            id="tab-home"
            md-label="上架状态"
            md-icon="home"
            class="text-gray"
          >

            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-radio
                  name="cut-title"
                  v-model="settings.sku.status"
                  value="is_draft"
                  >保存到草稿箱</md-radio
                >
                <md-radio
                  name="shipment-limit-second"
                  v-model="settings.sku.status"
                  value="is_onsale"
                  >直接上架</md-radio
                >
              </div>
            </div>
          </md-tab> -->
          <!-- 价格与库存 -->
          <md-tab
            id="tab-price"
            ref="tab-price"
            md-label="价格与库存"
            md-icon="price_change"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-10">
                <md-field>
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>团购价 =</label>
                  <md-input disabled value="抓取价格"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-10">
                <md-field>
                  <label for="tuangou-operator">算术符号</label>
                  <price-options
                    name="tuangou-operator"
                    v-model="settings.sku.tuangou_strategy.operator"
                  ></price-options>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="
                    getSkuPriceValidationClass('tuangou_strategy', 'value')
                  "
                >
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>计算值</label>
                  <md-input
                    :error="
                      $v.settings.sku.tuangou_strategy.value.$error
                        ? 'tab-price'
                        : null
                    "
                    v-model.number="settings.sku.tuangou_strategy.value"
                    type="number"
                    step="0.01"
                    min="0.01"
                  ></md-input>
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.tuangou_strategy.value.required"
                    >请输入计算值！</span
                  >
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.tuangou_strategy.value.minValue"
                    >不能小于0.01!</span
                  >
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>例子</label>
                  <md-input disabled :value="tougouExample.text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-10">
                <md-field>
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>单买价 =</label>
                  <md-input disabled value="团购价"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-10">
                <md-field>
                  <label for="danmai-operator">算术符号</label>
                  <price-options
                    name="danmai-operator"
                    v-model="settings.sku.danmai_strategy.operator"
                  ></price-options>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="
                    getSkuPriceValidationClass('danmai_strategy', 'value')
                  "
                >
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>计算值</label>
                  <md-input
                    :error="
                      $v.settings.sku.danmai_strategy.value.$error
                        ? 'tab-price'
                        : null
                    "
                    v-model.number="settings.sku.danmai_strategy.value"
                    type="number"
                    step="0.01"
                    min="0.01"
                  ></md-input>
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.danmai_strategy.value.required"
                    >请输入计算值！</span
                  >
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.danmai_strategy.value.minValue"
                    >不能小于0.01!</span
                  >
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>例子</label>
                  <md-input disabled :value="danmaiExample.text"></md-input>
                </md-field>
              </div>
            </div>
            <!--市场价-->
            <div class="md-layout">
              <div class="md-layout-item md-size-10">
                <md-field>
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>市场价 =</label>
                  <md-input disabled value="最高价"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-10">
                <md-field>
                  <label for="shichang-operator">算术符号</label>
                  <price-options
                    name="shichang-operator"
                    v-model="settings.sku.shichang_strategy.operator"
                  ></price-options>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="
                    getSkuPriceValidationClass('shichang_strategy', 'value')
                  "
                >
                  <!-- <md-icon class="md-accent">warning</md-icon> -->
                  <label>计算值</label>
                  <md-input
                    :error="
                      $v.settings.sku.shichang_strategy.value.$error
                        ? 'tab-price'
                        : null
                    "
                    v-model.number="settings.sku.shichang_strategy.value"
                    type="number"
                    step="0.01"
                    min="0.01"
                  ></md-input>
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.shichang_strategy.value.required"
                    >请输入计算值！</span
                  >
                  <span
                    class="md-error"
                    v-if="!$v.settings.sku.shichang_strategy.value.minValue"
                    >不能小于0.01!</span
                  >
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label>例子</label>
                  <md-input disabled :value="shichangExample.text"></md-input>
                </md-field>
              </div>
            </div>
          </md-tab>
          <md-tab
            id="tab-posts"
            md-label="分类与发货承诺"
            md-icon="local_shipping"
          >
            <!-- 承诺发货时间 -->
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <p class="text-muted">承诺发货时间</p>
                <md-radio
                  name="shipment-limit-second"
                  v-model="settings.service.shipment_limit_second"
                  :value="86400"
                  >24小时</md-radio
                >
                <md-radio
                  name="cut-title"
                  v-model="settings.service.shipment_limit_second"
                  :value="172800"
                  >48小时</md-radio
                >
              </div>
            </div>

            <!-- 团购人数 -->
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label for="customer-num">团购人数</label>
                  <customer-num
                    name="customer-num"
                    v-model="settings.service.customer_num"
                  ></customer-num>
                </md-field>
              </div>
            </div>

            <!-- 7天无理由退换货 -->
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-checkbox v-model="settings.service.is_refundable"
                  >7天无理由退换货</md-checkbox
                >
              </div>
            </div>

            <!-- 假一赔十 -->
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-checkbox v-model="settings.service.is_folt"
                  >假一赔十</md-checkbox
                >
              </div>
            </div>
          </md-tab>
        </md-tabs>
        <!-- <div>{{ settings }}</div> -->
        <!-- <md-card-actions mdAlignment="space-between"> -->
          <!-- <md-button type="submit" class="md-primary">测试</md-button> -->
          <!-- <slot /> -->
        <!-- </md-card-actions> -->
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import ShippingTemplate from "../../pdd/ShippingTemplate.vue";
import CustomerNum from "../../pdd/CustomerNum.vue";
import PriceOptions from "../../pdd/PriceOptions.vue";
import { round, evaluate } from "mathjs";
import { pluck, debounceTime } from "rxjs/operators";

import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  components: { ShippingTemplate, CustomerNum, PriceOptions },
  mixins: [validationMixin],
  props: {
    activeTab: {
      type: String,
      required: false,
      default: "tab-home",
    },
  },
  data() {
    return {
      ready: false,
      choices: {
        cutTitleLeft: true,
        cutTitleRight: false,
      },
      settings: null,
    };
  },
  validations: {
    settings: {
      service: {
        cost_template_id: {
          required,
        },
      },
      sku: {
        tuangou_strategy: {
          value: {
            required,
            minValue: minValue(0.01),
          },
        },
        danmai_strategy: {
          value: {
            required,
            minValue: minValue(0.01),
          },
        },
        shichang_strategy: {
          value: {
            required,
            minValue: minValue(0.01),
          },
        },
      },
    },
  },
  computed: {
    tougouExample: function () {
      // 抓取 价格 10
      // 计算价格

      let strategry = this.settings.sku.tuangou_strategy;

      if (!strategry.value) {
        return false;
      }

      let productPrice = round(strategry.value + 10, 0);
      let result = round(
        evaluate(productPrice + strategry.operator + strategry.value),
        2
      );

      return {
        val: result,
        text: `假如抓取的价格是 ${productPrice}元，  团购价格: ${productPrice} ${strategry.operator} ${strategry.value} = ${result} 元`,
      };
    },
    danmaiExample: function () {
      // 抓取 价格 10
      // 计算价格
      let strategry = this.settings.sku.danmai_strategy;
      if (!this.tougouExample) {
        return false;
      }

      let productPrice = round(this.tougouExample.val, 2);
      let result = round(
        evaluate(productPrice + strategry.operator + strategry.value),
        2
      );

      return {
        val: result,
        text: `团购价 ${productPrice}元，  单买价格: ${productPrice} ${strategry.operator} ${strategry.value} = ${result} 元`,
      };
    },
    shichangExample: function () {
      // 抓取 价格 10
      // 计算价格
      let strategry = this.settings.sku.shichang_strategy;
      if (!this.danmaiExample) {
        return false;
      }
      let productPrice = round(this.danmaiExample.val, 2);

      let result = round(
        evaluate(productPrice + strategry.operator + strategry.value),
        2
      );

      return {
        val: result,
        text: `最高价 ${productPrice}元，  市场价格: ${productPrice} ${strategry.operator} ${strategry.value} = ${result} 元`,
      };
    },
  },
  methods: {
    updateSettings: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.dispatch("settings/update", this.settings);
    },
    onTabChange(tabId) {
      this.$emit("update:activeTab", tabId);
    },
    // focusFirstStatus(component = this) {
    //   if (component.status) {
    //     component.$el.focus()
    //     return true
    //   }

    //   let focused = false

    //   component.$children.some((childComponent) => {
    //     focused = this.focusFirstStatus(childComponent)
    //     return focused
    //   })

    //   return focused
    // },
    validateForm() {
      this.$v.$touch();

      // if (this.$v.$invalid) {
      //   // 1. Loop the keys
      //   for (let key in Object.keys(this.$v)) {
      //     // 2. Extract the input
      //     const input = Object.keys(this.$v)[key]
      //     // 3. Remove special properties
      //     if (input.includes('$')) return false

      //     // 4. Check for errors
      //     if (this.$v[input].$error) {
      //       // 5. Focus the input with the error
      //       this.$refs[input].focus()

      //       // 6. Break out of the loop
      //       break
      //     }
      //   }
      // }

      return !this.$v.$invalid;
    },
    getServiceValidationClass(fieldName) {
      const field = this.$v.settings.service[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    getSkuPriceValidationClass(type, fieldName) {
      const field = this.$v.settings.sku[type][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("settings/get")
      .then((settings) => {
        this.settings = settings;
      })
      .catch(() => {
        this.settings = {
          sku: {
            title: true,
            status: "is_draft", // 直接上架，保存到草稿箱 is_onsale
            quantity: 999,
            tuangou_strategy: {
              operator: "*",
              value: 1,
            }, //团购价格
            danmai_strategy: {
              operator: "+",
              value: 1,
            }, //单买价格
            shichang_strategy: {
              operator: "*",
              value: 1.5,
            }, //市场价
          },
          service: {
            cost_template_id: null, //运费模版
            shipment_limit_second: 172800, //承诺发货时间，48小时
            customer_num: 2, //团购人数
            is_refundable: true, //7天无理由退换货
            is_folt: true, // 假一赔十
          },
        };
      })
      .finally(() => {
        this.ready = true;
        this.validateForm();

        this.$watchAsObservable("settings", { deep: true })
          .pipe(debounceTime(500), pluck("newValue"))
          .subscribe(() => {
            this.updateSettings();
          });
      });
  },
};
</script>

<style lang="scss">
.md-tabs.md-transparent {
  background-color: transparent;

  .md-tabs-navigation {
    box-shadow: none;
    margin-top: 0;
    padding: 0 0 0 15px;

    button,
    .md-icon {
      color: grey !important;
    }

    .md-tabs-indicator {
      display: block;
    }
  }

  .md-tabs-content {
    .md-field {
      margin-bottom: 24px;
    }
  }
}
</style>
