<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>task_alt</md-icon>
      </div>
      <h4 class="title">搬家记录</h4>
    </md-card-header>
    <md-card-content>
      <md-table
        :value="table"
        :md-sort.sync="sortation.field"
        :md-sort-order.sync="sortation.order"
        :md-sort-fn="customSort"
        class="paginated-table table-striped table-hover table-group"
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <md-field md-clearable @md-clear="reload">
              <md-icon>search</md-icon>
              <label>搜索店铺</label>
              <md-input v-model="keyword" @keydown.enter="search" />
            </md-field>
            <md-button type="submit" class="md-just-icon" @click="search"
              ><md-icon>arrow_forward</md-icon></md-button
            >
            &nbsp;
            <md-field>
              <label>状态</label>
              <md-select v-model="status" name="status" @md-selected="getList">
                <md-option
                  :value="statusText"
                  v-for="statusText in ['全部', '排队中', '上传中', '已完成', '错误']"
                  :key="statusText">{{ statusText }}</md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-toolbar-section-end">
            <md-field>
            <label>每页显示条数</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
                {{ item }}
              </md-option>
            </md-select>
          </md-field>

          </div>

        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="店铺名称" class="shop_info"
            ><div class="photo">
              <img :src="item.shopInfo.logo" alt="avatar" />
            </div>
            <span>{{ item.shopInfo.name }}</span>
            <md-tooltip md-direction="bottom"
              >授权到期时间：{{ item.shopInfo.expire | expire }}</md-tooltip
            >
          </md-table-cell>

          <md-table-cell md-label="任务创建时间">{{
            item.createdAt | moment
          }}</md-table-cell>
          <md-table-cell md-label="原地址"
            ><a target="_blank" :href="item.url" class="target_url">{{
              item.url
            }}</a></md-table-cell
          >
          <md-table-cell md-label="状态">
            <span v-if="item.status == '错误'">
              {{ item.status }} <md-icon class="error md-accent">error</md-icon>
              <md-tooltip md-direction="top">{{ item.error }}</md-tooltip>
            </span>

            <span v-else>{{ item.status }} </span></md-table-cell
          >
          <md-table-cell md-label="商品名称">{{ item.title }}</md-table-cell>

          <md-table-cell md-label="操作">
            <drop-down>
              <a
                slot="title"
                class="dropdown-toggle"
                data-toggle="dropdown"
                href="javascript:void(0)"
              >
                <md-icon>more_vert</md-icon>
              </a>
              <div>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li>
                    <a href="#" @click="resetHistory(item)">重置</a>
                  </li>

                  <li>
                    <a href="#" @click="deleteHistory(item)">删除</a>
                  </li>

                  <li>
                    <a href="#" @click="gotoShop(item.shopInfo)"
                      >查看店铺信息</a
                    >
                  </li>
                </ul>
              </div>
            </drop-down>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-dialog-confirm
        :md-active.sync="resetActive"
        md-title="确定要重置吗?"
        :md-content="
          '当前正要重置: &nbsp; <strong>' + activeHistory?.id + '</strong>'
        "
        md-confirm-text="确定"
        md-cancel-text="取消"
        @md-confirm="onConfirmHistoryReset"
        @md-cancel="resetActive = false"
      />

      <md-dialog-confirm
        :md-active.sync="deleteActive"
        md-title="确定要删除吗?"
        :md-content="
          '当前正要删除: &nbsp; <strong>' + activeHistory?.id + '</strong>'
        "
        md-confirm-text="确定"
        md-cancel-text="取消"
        @md-confirm="onConfirmHistoryDelete"
        @md-cancel="deleteActive = false"
      />
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div v-if="total > 0">
        <p class="card-category">
          当前 {{ from + 1 }} 到 {{ to }}， 共 {{ total }} 条记录
        </p>
      </div>
      <div v-if="total == 0">
        <p class="card-category">暂无记录</p>
      </div>
      <pagination
        v-if="total > 0"
        class="pagination-no-border pagination-success"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </md-card-actions>
  </md-card>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
import debounce from "lodash/debounce";

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    table: [],
    // footerTable: ['Name', 'Email', 'Created At', 'Actions'],
    activeHistory: null, // 选中的历史记录
    deleteActive: false,
    resetActive: false,
    query: null,
    status: '全部',
    keyword: "",

    sortation: {
      field: "created_at",
      order: "asc",
    },

    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: 0,
  }),
  watch: {
    pagination: {
      deep: true,
      handler: function (newValue) {
        this.getList();
      },
    },
  },

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    this.getList();
  },

  filters: {
    moment: function (dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
    expire: function (unixTime) {
      return moment.unix(unixTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    async getList() {
      let response = await this.$http.get("/admin/histories", {
        params: {
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          query: this.query,
          status: this.status == "全部" ? null : this.status,
        },
      });

      this.total = response.data.pagination.rowCount;
      this.table = response.data.items;
    },

    customSort() {
      return false;
    },

    onCancel() {},
    gotoShop(shop) {
      this.$router.push({
        name: "店铺信息",
        params: { id: shop.id },
      });
    },
    resetHistory(item) {
      this.resetActive = true;
      this.activeHistory = item;
    },
    onConfirmHistoryReset() {
      this.$http
        .patch(`/admin/histories/${this.activeHistory.id}`, {
          status: "排队中",
          error: null,
        })
        .then(() => {
          this.getList();
          this.activeHistory = null;
          this.resetActive = false;
          this.$store.dispatch("alerts/success", "重置成功!");
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.message);
        });
    },
    deleteHistory(item) {
      this.deleteActive = true;
      this.activeHistory = item;
    },
    onConfirmHistoryDelete() {
      this.$http
        .delete(`/admin/histories/${this.activeHistory.id}`)
        .then(() => {
          this.getList();
          this.activeHistory = null;
          this.deleteActive = false;
          this.$store.dispatch("alerts/success", "删除成功!");
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.message);
        });
    },
    search: debounce(function () {
      if (this.query === this.keyword) {
        return;
      }

      this.query = this.keyword;
      this.getList();
    }, 200),
    reload() {
      // 只在有搜索关键字的时候才会触发reload
      if (this.query) {
        this.query = null;
        this.getList();
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style>
.target_url {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.md-dialog-content p {
  display: inline-block;
  max-width: 500px;
  word-break: break-all;
}

.align-right {
  text-align: right;
}

.photo {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

/* 把div里面的span上下居中 */
.shop_info div.md-table-cell-container {
  display: flex;
  align-items: center;
}

.table-group,
.table-group .md-table-content,
.table-group table {
  overflow: visible;
}
</style>
