<script>
import ShopService from '@/store/services/shop-service'
import CustomerNum from '@/pdd/CustomerNum.vue'

export default {
  extends: CustomerNum,

  methods: {
    async getOptions() {
      let templates = await ShopService.getShippingTemplates()

      return templates.map((template) => {
        return {
          label: template.template_name,
          value: template.template_id,
        }
      })
    },
  },
}
</script>
