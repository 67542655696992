<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>task_alt</md-icon>
        </div>
        <h4 class="title">
          群组 -
          <small
            >通过创建群组，群创建者可以在上传产品的时候选择同时上传到群组里，这个任务会被添加到群组里成员的任务列表，实现多店上传的能力。</small
          >
        </h4>
        <small>在搬家助手页面，<strong>当您有群组，而且有成员的时候</strong>，会出现【同时上传到群组】选项，选中对应的群组就可以同步分发产品到群组。</small>
      </md-card-header>
      <md-card-content>
        <md-table
          :value="table"
          :md-sort.sync="sortation.field"
          :md-sort-order.sync="sortation.order"
          :md-sort-fn="customSort"
          class="paginated-table table-striped table-hover table-group"
        >
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <md-field md-clearable @md-clear="reload">
                <md-icon>search</md-icon>
                <label>按名称过滤</label>
                <md-input v-model="keyword" @keydown.enter="search" />
              </md-field>
              <md-button type="submit" class="md-just-icon" @click="search"
                ><md-icon>arrow_forward</md-icon></md-button
              >
              &nbsp;&nbsp;
              <md-button class="md-primary" @click="showDialog = true"
                ><md-icon>groups</md-icon>创建新群组</md-button
              >
              &nbsp;&nbsp;
              <md-button class="md-primary" @click="showJoinGroupDialog = true"
                ><md-icon>group_add</md-icon>加入群组</md-button
              >
            </div>

            <div class="md-toolbar-section-end">
              <md-field>
                <label>每页显示条数</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- </div> -->
            <!-- <div class="md-layout md-size-50"></div> -->
          </md-table-toolbar>
          <!-- 必须保留这个 {{  }}, 否则页面刷新会有点难受-->
          {{ }}
          <md-table-empty-state
            slot="md-table-empty-state"
            md-label="没有找到对应的群组"
            md-description="您可以创建新群组或者去加入已经存在的群组."
          >
          </md-table-empty-state>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="群名称">
              {{ item.name }}
            </md-table-cell>

            <md-table-cell md-label="群介绍" class="goods_id"
              >{{ item.description }}
            </md-table-cell>

            <md-table-cell md-label="创建时间">
              {{ item.createdAt | moment }}
            </md-table-cell>

            <md-table-cell md-label="创建者" class="shop_info">
              <div class="photo" v-if="item.creatorInfo">
                <img :src="item.creatorInfo?.logo" alt="avatar" />
              </div>
              <span>{{ item.creatorInfo?.name }}</span>
            </md-table-cell>

            <md-table-cell md-label="是否是群管理员">
              {{ isAdmin(item) ? "是" : "否" }}
            </md-table-cell>

            <md-table-cell md-label="操作" class="operation table-operation">
              <drop-down>
                <a
                  slot="title"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  href="javascript:void(0)"
                >
                  <md-icon>more_vert</md-icon>
                </a>
                <div>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                      <a href="#" v-if="isAdmin(item)" @click="getCode(item)"
                        >获取邀请码</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        v-if="isAdmin(item)"
                        @click="getShopList(item)"
                        >查看成员列表</a
                      >
                    </li>
                    <!-- <li><a href="#" v-if="isAdmin(item)">转让管理员</a></li> -->
                    <!-- <md-divider></md-divider> -->
                    <li>
                      <a
                        href="#"
                        v-if="isAdmin(item)"
                        @click="deleteGroupConfirm(item)"
                        >解散</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        v-if="!isAdmin(item)"
                        @click="exitGroupConfirm(item)"
                        >退出</a
                      >
                    </li>
                  </ul>
                </div>
              </drop-down>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-dialog-confirm
          :md-active.sync="exitActive"
          md-title="确定要退出吗?"
          :md-content="
            '当前您正要退出群组: &nbsp; <strong>' +
            activeGroup?.name +
            '</strong>'
          "
          md-confirm-text="确定"
          md-cancel-text="取消"
          @md-confirm="onConfirmGroupExit"
          @md-cancel="onCancelGroupExit"
        />

        <md-dialog-confirm
          :md-active.sync="deleteActive"
          md-title="确定要解散吗? 解散后无法恢复！"
          :md-content="
            '当前您正要解散群组: &nbsp; <strong>' +
            activeGroup?.name +
            '</strong>'
          "
          md-confirm-text="确定"
          md-cancel-text="取消"
          @md-confirm="onConfirmGroupDelete"
          @md-cancel="onCancelGroupDelete"
        />
      </md-card-content>

      <md-card-actions md-alignment="space-between">
        <div v-if="total > 0">
          <p class="card-category">
            当前 {{ from + 1 }} 到 {{ to }}， 共 {{ total }} 条记录
          </p>
        </div>
        <div v-if="total == 0">
          <p class="card-category">暂无记录</p>
        </div>
        <pagination
          v-if="total > 0"
          class="pagination-no-border pagination-success"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </md-card-actions>
    </md-card>
    <md-dialog
      :md-active.sync="showDialog"
      :md-click-outside-to-close="false"
      class="create-group-dialog"
    >
      <md-dialog-title>创建新群组</md-dialog-title>
      <p class="md-caption text-warning">
        创建群组之后，请点击列表中【操作】一列对应的三个点，通过【获取邀请码】，将邀请码分享给其他人加入群组。
      </p>

      <md-dialog-content>
        <md-field :class="getValidationClass('name')">
          <label>群名称</label>
          <md-input v-model="group.name" required maxlength="50"></md-input>
          <span class="md-error" v-if="!$v.group.name.required"
            >请输入群名称!</span
          >
          <span class="md-error" v-else-if="!$v.group.name.maxLength"
            >群名称不能超过50个字符!</span
          >
        </md-field>
        <md-field>
          <label>群介绍</label>
          <md-input v-model="group.description" maxlength="250"></md-input>
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button @click="cancelCreateGroup">取消</md-button>
        <md-button class="md-primary" @click="createNewGroup">创建</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog
      :md-active.sync="showJoinGroupDialog"
      :md-click-outside-to-close="false"
      class="join-group-dialog"
    >
      <md-dialog-title>加入群组</md-dialog-title>

      <md-dialog-content>
        <join-group
          @joinEvent="
            getList();
            showJoinGroupDialog = false;
          "
        ></join-group>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button @click="showJoinGroupDialog = false">取消</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="showCodeDialog"
      :md-click-outside-to-close="false"
      class="code-dialog"
    >
      <md-dialog-title>群组[{{ activeGroup?.name }}]的邀请码:</md-dialog-title>

      <md-dialog-content>
        <p class="code text-warning">
          {{ code?.code
          }}<button class="copy-button" @click="copyCode(code.code)">
            <md-icon>content_copy</md-icon>
            <md-tooltip md-direction="top">点击复制到剪贴板</md-tooltip>
          </button>
        </p>
        <p class="help">
          <small>有效期至: {{ code?.expire_date | unix }}</small>
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          @click="
            code = null;
            showCodeDialog = false;
          "
          >关闭</md-button
        >
        <md-button class="md-primary" @click="refreshCode"
          ><md-icon>refresh</md-icon>刷新邀请码</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="showMembersDialog"
      :md-click-outside-to-close="false"
      class="members-dialog"
    >
      <!-- <md-dialog-title>成员列表</md-dialog-title> -->

      <md-dialog-content>
        <member-list v-if="activeGroup" :groupId="activeGroup.id"></member-list>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button @click="showMembersDialog = false">关闭</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
import shopService from "../../../store/services/shop-service";
import JoinGroupDialogVue from "./JoinGroupDialog.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import debounce from "lodash/debounce";
import copy from "copy-to-clipboard";
import MemberList from "./MemberList.vue";

export default {
  components: {
    pagination: Pagination,
    joinGroup: JoinGroupDialogVue,
    memberList: MemberList,
  },

  mixins: [validationMixin],

  validations: {
    group: {
      name: {
        required,
        maxLength: maxLength(50),
      },
    },
  },

  data: () => ({
    loading: false,
    showDialog: false,
    showJoinGroupDialog: false,
    showMembersDialog: false,

    showCodeDialog: false,
    code: null,
    activeGroup: null,

    deleteActive: false, //删群 dialog 控制
    exitActive: false, //退群dialog 控制
    group: {
      name: "",
      description: "",
    },
    table: [],
    // footerTable: ['Name', 'Email', 'Created At', 'Actions'],
    keyword: null,
    query: null,

    sortation: {
      field: "created_at",
      order: "asc",
    },

    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: 0,
  }),
  watch: {
    pagination: {
      deep: true,
      handler: function () {
        this.getList();
      },
    },
  },

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    this.getList();
  },

  filters: {
    moment: function (datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
    },
    unix: function (unixTime) {
      return moment.unix(unixTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    getShopList(group) {
      this.activeGroup = group;
      this.showMembersDialog = true;
    },
    copyCode(code) {
      copy(code);
      this.$store.dispatch("alerts/success", "邀请码已经复制到剪贴板。");
    },
    getCode(group) {
      this.activeGroup = group;
      shopService
        .getCode(group.id)
        .then((res) => {
          this.code = res;
          this.showCodeDialog = true;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.message);
        });
    },
    refreshCode() {
      shopService
        .refreshCode(this.activeGroup.id)
        .then((res) => {
          this.code = res;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.message);
        });
    },
    cancelCreateGroup() {
      this.showDialog = false;
      this.resetGroup();
      this.$v.$reset();
    },
    createNewGroup() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        shopService
          .createGroup(this.group)
          .then(() => {
            this.showDialog = false;
            this.$v.$reset();
            this.resetGroup();
            this.getList();
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data.errors) {
                this.$store.dispatch(
                  "alerts/error",
                  err.response.data.errors.map((e) => e.msg).join(", ")
                );
                return;
              }

              if (err.response.data.error) {
                this.$store.dispatch("alerts/error", err.response.data.error);
                return;
              }
            }

            this.$store.dispatch("alerts/error", err.message);
          });
      }
    },
    resetGroup() {
      this.group = {
        name: "",
        description: "",
      };
      this.$v.$reset();
    },
    isAdmin(group) {
      return group.members[0].isAdmin;
    },
    deleteGroupConfirm(group) {
      this.activeGroup = group;
      this.deleteActive = true;
    },
    onConfirmGroupDelete() {
      shopService
        .deleteGroup(this.activeGroup.id)
        .then((res) => {
          this.$store.dispatch("alerts/success", "群解散成功。");
          this.getList();
          this.deleteActive = false;
          this.activeGroup = null;
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.response.data.error);
        });
    },
    exitGroupConfirm(group) {
      this.activeGroup = group;
      this.exitActive = true;
    },
    onCancelGroupDelete() {
      this.activeGroup = null;
      this.deleteActive = false;
    },
    onConfirmGroupExit() {
      shopService.exitGroup(this.activeGroup.id).then((res) => {
        this.$store.dispatch("alerts/success", "退出成功。");
        this.getList();
        this.exitActive = false;
        this.activeGroup = null;
      });
    },
    onCancelGroupExit() {
      this.exitActive = false;
      this.activeGroup = null;
    },
    async reload() {
      // 只在有搜索关键字的时候才会触发reload
      if (this.query) {
        this.query = null;
        this.getList();
      }
    },
    search: debounce(function () {
      if (this.query === this.keyword) {
        return;
      }

      this.query = this.keyword;
      this.getList();
    }, 200),
    async getList() {
      this.loading = true;
      let response = await this.$http.get("/groups/my", {
        params: {
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          query: this.query,
        },
      });

      this.total = response.data.pagination.rowCount;
      this.table = response.data.items;
      this.loading = false;
    },

    customSort() {
      return false;
    },

    onCancel() {},

    getValidationClass(fieldName) {
      const field = this.$v.group[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style>
.create-group-dialog .md-dialog-container {
  width: 400px !important;
}

.create-group-dialog .md-dialog-title {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.create-group-dialog .md-caption {
  padding: 0 24px 0;
}

.join-group-dialog .md-dialog-container {
  width: 500px !important;
}

.code-dialog .md-dialog-container{
  width: 400px !important;
}

.members-dialog .md-dialog-container {
  width: 500px !important;
  min-height: 350px;
}

.members-dialog .md-dialog-content:first-child {
  padding-top: 0px !important;
}


.code-dialog .md-dialog-container p.code {
  /* 这部分是现实code，我希望是居中，有个背景色，让字体充满 背景色 */
  background-color: #f5f5f5;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  height: 60px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  letter-spacing: 10px;
}

.code-dialog .md-dialog-container p.help {
  padding: 0px;
  margin: 0px;
  font-style: italic;
}

.code-dialog .md-dialog-container p.code i {
  margin: 0;
}

.photo {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

/* 把div里面的span上下居中 */
.shop_info div.md-table-cell-container {
  display: flex;
  align-items: center;
}

.operation .md-table-cell-container i {
  margin-right: 20px;
  /* 透明度50% */
  opacity: 0.5;
}
.table-group,
.table-group .md-table-content,
.table-group table {
  overflow: visible;
}
</style>
<style scoped>
.copy-button {
  background: none;
  border: none;
  cursor: pointer;
}
</style>
