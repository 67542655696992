<template>
   <md-autocomplete
    v-model="selected"
    :md-options="items"
  >
    <label>{{settings.name}}</label>

     <template slot="md-autocomplete-empty" slot-scope="{ term }">
        没有匹配到 "{{ term }}" 的选项
      </template>
  </md-autocomplete>
</template>

<script>

import { AutoCompleteItem } from "@/pdd/PddCategoryObject.js"
export default {
  props: {
    value: { type: [Object, String], required: false, default: null },
    settings: { type: Object, required: true},

  },
  data: () => ({
    items: [],
    selected: null,
  }),
  watch: {
    value: function(newValue, oldVal) {

    }
  },
  created () {
    if (this.value) {
      this.selected = new AutoCompleteItem(this.value.valueId, this.value.valueText)
    }

    // 下拉选项
    this.items = this.settings.values.map((item) => {
      return new AutoCompleteItem(item.vid, item.value)
    })

    this.$watch('selected', function(newVal) {
      // 如果newVal 是string
      if (typeof newVal === 'string') {
        newVal = this.items.find(item => item.name === newVal)
      }

      if (!newVal) {
        this.$emit('input', null)
        return
      }

      this.$emit('input', {
        valueId: newVal.id,
        valueText: newVal.name,
        id: this.settings.ref_pid,
        name: this.settings.name,
      })
    })

  },
  methods: {

  }
}
</script>

<style>

</style>
