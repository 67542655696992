<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-toolbar-section-start">
        <md-field md-clearable @md-clear="reload">
          <!-- <md-icon>search</md-icon> -->
          <label>请在这里输入邀请码,然后按回车或点击右边的箭头确认</label>
          <md-input required v-model="keyword" @keydown.enter="search" />
          <span class="md-helper-text">邀请码是6位的数字</span>
        </md-field>
        <md-button type="submit" class="md-just-icon" @click="search"
          ><md-icon>arrow_forward</md-icon></md-button
        >
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item" style="padding-top: 10px;">
        <!-- <md-progress-bar md-mode="indeterminate" v-if="loading"></md-progress-bar> -->
        <div class="md-empty-state-container" v-if="group == null">
          <strong class="md-empty-state-label">{{ message }}</strong>
          <p class="md-empty-state-description">
            只有通过邀请码才能加入群组，如果您没有邀请码，请联系群管理员获取。
          </p>
        </div>
        <div v-if="group">
          <div style="display: flex; align-items: center">
            <!-- <div style="margin-right: 10px;">
              <img :src="group.creatorInfo.logo" alt="Logo" style="width: 50px; height: 50px;">
            </div> -->
            <div style="display: flex; flex-direction: column">
              <strong>{{ group.name }}</strong>
              <p>{{ group.description }}</p>
              <span
                >由&nbsp;<strong>{{ group.creatorInfo.name }}</strong>
                创建于:&nbsp;<strong>{{
                  group.createdAt | moment
                }}</strong></span
              >
            </div>
          </div>

          <md-button
            v-if="group.members.length == 0"
            class="md-primary"
            @click="apply(group)"
          >
            <md-icon>group_add</md-icon>
            加入群组
          </md-button>
          <div v-else>
            <p class="text-success">您已经加入这个群了。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopService from "../../../store/services/shop-service";
import moment from "moment";
import debounce from "lodash/debounce";

export default {
  components: {},
  data: () => ({
    loading: false,
    first: true, //第一次进页面，没有搜索过
    keyword: null,
    query: null,
    group: null,
    message: null,
    defaultMessage: '请联系群管理员获取邀请码',
    emptyMessage: "没有找到匹配的群组!",
  }),

  computed: {},

  created() {
    //this.getList();
    this.message = this.defaultMessage;
  },

  filters: {
    moment: function (datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    async reload() {
      // 只在有搜索关键字的时候才会触发reload
      if (this.query) {
        this.query = null;
        this.group = null;
        this.message = this.defaultMessage;
      }
    },
    search: debounce(function () {
      if (this.query == this.keyword) {
        return;
      }

      this.query = this.keyword;

      if (this.query.trim() == "") {
        this.group = null;
        this.message = this.defaultMessage;
        return;
      }

      this.getGroup();
      this.message = this.emptyMessage;
    }, 200),
    async getGroup() {
      // this.loading = true;
      this.group = await shopService.getGroupByCode(this.query);
      // this.loading = false;
    },

    onCancel() {},
    apply() {
      shopService
        .applyGroup(this.group.id, this.group.key)
        .then((res) => {
          // 弹出消息 提示，申请成功
          this.$store.dispatch(
            "alerts/success",
            `您加入了群组【${this.group.name}】。`
          );
          // 关闭dialog
          this.$emit("joinEvent");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.errors) {
              this.$store.dispatch(
                "alerts/error",
                err.response.data.errors.map((e) => e.msg).join(", ")
              );
              return;
            }

            if (err.response.data.error) {
              this.$store.dispatch("alerts/error", err.response.data.error);
              return;
            }
          }

          this.$store.dispatch("alerts/error", err.message);
        });
    },
  },
  beforeDestroy() {},
};
</script>

<style></style>
