import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    current: null, //当前店铺
  },
  getters: {
    current: (state) => state.current,
  },
  actions: {
    setCurrentMall({ commit, dispatch }, payload) {
      return Vue.$http.get('/shop').then((response) => {
        commit('setMall', response.data)
      })
    }
  },
  mutations: {
    setMall(state, mall) {
      state.current = mall
    }
  },
}
