<script>
import CustomerNum from './CustomerNum.vue'

export default {
  extends: CustomerNum,
  props: {
    value: {
      type: String,
      required: false,
    }, //默认值
  },

  methods: {
    getOptions() {
      return Promise.resolve([
        {
          value: '*',
          label: '*',
        },
        {
          value: '/',
          label: '/',
        },
        {
          value: '+',
          label: '+',
        },
        {
          value: '-',
          label: '-',
        },
      ])
    },
  },
}
</script>
