<template>
  <div>
    <!-- <form novalidate @submit.prevent="validateForm"> -->
    <form novalidate>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>content_copy</md-icon>
          </div>
          <h4 class="title">输入链接搬家，支持：淘宝、天猫、1688、京东</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-icon>warning</md-icon
              >禁止未经允许复制他人商品，请获取授权后进行商品复制！
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field :class="getValidationClass('url')">
                <label for="url">请输入宝贝地址！ *您可以使用Ctrl+v 或者鼠标右键粘贴 复制好的地址。</label>
                <md-input
                  :error="$v.url.$error ? 'error' : null"
                  v-model="url"
                  required
                  name="url"
                ></md-input>
                <span class="md-error" v-if="!$v.url.required"
                  >请输入宝贝地址！</span
                >
                <span class="md-error" v-if="!$v.url.url"
                  >请输入正确的宝贝地址！</span
                >
              </md-field>

              <md-field v-if="groups.length > 0" md-clearable>
                <label for="group">同时上传到群组（多选）</label>
                <md-select v-model="selectGroups" name="grups" id="groups" multiple>
                  <md-option v-for="item in groups" :key="item.id" :value="item.id">{{item.name}}</md-option>
                </md-select>
              </md-field>

              <md-button
                @click="validateForm"
                class="md-primary"
                :disabled="sending"
                >开始搬家</md-button
              >
            </div>
          </div>
          <div class="md-layoout">
            <div class="md-layout-item">
              <div>
                <md-checkbox v-model="checkboxValue">我已同意声明</md-checkbox>
                <span class="agree-error" v-if="!checkboxValue"
                  >请确认声明!</span
                >
              </div>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-icon>info</md-icon
              ><span class="md-caption"
                >声明，由于平台和平台之间存在差异，产品复制过程中可能会导致部分属性遗漏或不匹配的问题。
                请您在使用我们的软件时，仔细检查复制的产品，并确保其与您的预期一致。我们将尽力提供技术支持，帮助您解决可能出现的问题。
                然而，我们无法对由于平台差异而导致的属性遗漏或不匹配问题承担法律责任。感谢您的理解与支持。</span
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <settings-card ref="settingsCard" :activeTab.sync="activeTab">
      </settings-card>
      <task-list-card ref="taskListCart"></task-list-card>
    </form>
  </div>
</template>

<script>
import SettingsCard from "../../../components/Cards/SettingsCard.vue";
import { validationMixin } from "vuelidate";
import { required, url, sameAs } from "vuelidate/lib/validators";
import shopService from "../../../store/services/shop-service";
import TaskListCard from "../User/ShopManagement/TaskListCard.vue";

export default {
  mixins: [validationMixin],
  data() {
    return {
      sending: false, //是否正在提交数据
      url: null,
      selectGroups: null,
      activeTab: null,
      checkboxValue: true,
      groups: [],

    };
  },
  validations: {
    url: {
      required,
      url,
    },
    checkboxValue: {
      sameAs: sameAs(() => true),
    },
  },
  created: function () {
    // 获取用户管理的群组列表
    shopService
      .getMyAdminGroups()
      .then((groups) => {
        this.groups = groups;
      })
      .catch((err) => {
        this.$store.dispatch("alerts/error", err.response.data?.error);
      });

  },
  components: { SettingsCard, TaskListCard },
  methods: {
    move: function () {
      // console.log(this.$store.getters['settings/settings'].sku.status)
    },

    getValidationClass(fieldName) {
      const field = this.$v[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    focusFirstStatus(component = this) {
      if (component.$attrs.error) {
        let tabId = null;
        if (component.$attrs.error != "error") {
          tabId = component.$attrs.error;
          this.activeTab = tabId;
        }

        if (!tabId) {
          component.$el.focus();
        }

        // this.$nextTick(() => {
        //   component.$el.focus()
        // })

        // setTimeout(() => {
        //   component.$el.focus()
        // }, 1500)

        return true;
      }

      let focused = false;

      component.$children.some((childComponent) => {
        focused = this.focusFirstStatus(childComponent);
        return focused;
      });

      return focused;
    },
    validateForm() {
      this.$v.$touch();

      let res = this.$refs.settingsCard.validateForm();
      this.$nextTick(() => this.focusFirstStatus());

      if (!this.$v.$invalid && res) {
        shopService
          .createTask(this.url, this.$store.state.settings.settings, this.selectGroups?.filter(Boolean))
          .then(() => {
            this.url = "";
            this.$refs.taskListCart.getList();
            this.$v.$reset();
            this.$store.dispatch("alerts/success", '任务已经加入排队队列！【任务状态会在下面的搬家记录里自动更新】');
          })
          .catch((err) => {
            this.$store.dispatch("alerts/error", err.response.data?.error);
          });
      } else {
        this.$store.dispatch("alerts/error", "请检查页面的输入！");
      }
    },
  }
};
</script>

<style lang="scss">
.agree-error {
  color: #ff1744;
  display: block;
  font-size: 12px;
  position: relative;
  top: -15px;
}

.md-invalid span.md-error {
  bottom: -5px;
}
</style>
