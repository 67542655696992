<template>
  <md-field md-clearable>
    <label>{{settings.name}} {{ isMultiple ? `- 多选，最多选${settings.choose_max_num}个` : "" }}</label>
    <!-- <span class="helper-text" v-if="isMultiple">{{ `多选，最多选${settings.choose_max_num}个` }}</span> -->
    <md-select v-model="selected" :multiple="isMultiple" :required="settings.required">
      <md-option v-for="item in settings.values" :key="item.vid" :value="item.vid">
        {{item.value}}
      </md-option>
    </md-select>

  </md-field>
</template>

<script>

export default {
  props: {
    value: { type: [Object, Array], required: false, default: null },
    settings: { type: Object, required: true},
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    isMultiple() {
      return this.settings.choose_max_num > 1
    }
  },
  watch: {

  },
  created () {
    if (this.value) {
      this.selected = this.value.valueId
    }

    this.$watch('selected', function(newVal) {
      if (newVal == '') {
        this.$emit('input', null)
        return
      }

      // 如果newVal 是number
      if (typeof newVal === 'number') {
        newVal = this.settings.values.find(item => item.vid === newVal)
      }

      // 如果newVal 是array
      if (Array.isArray(newVal)) {
        let items  = newVal.filter(Boolean).map(item => {
          return this.settings.values.find(value => value.vid === item)
        })

        return this.$emit('input', {
          valueId: items.map(item => item.vid),
          valueText: items.map(item => item.value),
          id: this.settings.ref_pid,
          name: this.settings.name,
        })
      }

      if (!newVal) {
        this.$emit('input', null)
        return
      }

      this.$emit('input', {
        valueId: newVal.vid,
        valueText: newVal.value,
        id: this.settings.ref_pid,
        name: this.settings.name,
      })
    })

  },
  methods: {

  }
}
</script>

<style>
span.helper-text {
    /* color: #6c757d !important; */
    right: 0px;
    top: -18px;
    /* opacity: 0; */
    font-size: 0.75rem;
    /* visibility: hidden; */
    height: 20px;
    position: absolute;
    /* bottom: -22px; */
    font-size: 11px;
    color: rgba(0, 0, 0, 0.54);
    /* transition: .3s cubic-bezier(.4,0,.2,1); */
}
</style>
