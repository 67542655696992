<template>
  <div>
    <div>组件类型: {{ settings.control_type }} 还未实现，如果需要，请联系我处理。</div>
    <!-- <div>{{ settings }}</div> -->
    </div>

</template>

<script>

export default {
  props: {
    value: { type: Object, required: false, default: null },
    settings: { type: Object, required: true},

  },
  data: () => ({

  }),

  mounted () {
    // debugger;
  },
  methods: {

  }
}
</script>

<style>

</style>
