<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>help_outline</md-icon>
      </div>
      <h4 class="title">简单三步走</h4>
    </md-card-header>
    <md-card-content>
      <md-steppers md-vertical>
        <md-step id="first" md-label="第一步" md-description="配置商品设置">
          <p>
            设置运费模版，价格规则，分类与发货承诺。（只需要设置一次)
          </p>
          <img :src="step1" />
        </md-step>

        <md-step id="second" md-label="第二步" md-description="复制要搬家的商品地址">
          <p>
            用浏览器去访问要搬家的商品地址，然后复制地址栏的链接。
          </p>
          <img :src="step2" />
          <p>
            把地址粘贴到这里,然后点击开始搬家。
          </p>
          <img :src="step3" />
        </md-step>

        <md-step id="third" md-label="第三步" md-description="等待系统后台完成搬家">
          <p>
            搬家记录会每隔一段时间自动刷新，您也可以手动刷新。
          </p>
           <img :src="step4" />
          <p>
            <ul>
              <li>您可以点击查看商品，会跳转到拼多多的商品编辑页面，查看商品详情。</li>
              <li>点击下载图片图标，可以下载产品对应的图片资源。</li>
            </ul>
          </p>
        </md-step>
      </md-steppers>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  data() {
    return {
      step1: `${process.env.VUE_APP_BASE_URL}/img/info/step1.png`,
      step2: `${process.env.VUE_APP_BASE_URL}/img/info/step2.png`,
      step3: `${process.env.VUE_APP_BASE_URL}/img/info/step3.png`,
      step4: `${process.env.VUE_APP_BASE_URL}/img/info/step4.png`,
    };
  },
};
</script>

<style>
.md-stepper button.md-stepper-header {
  background-color: #f8fbf8 !important;
  /* color: white !important; */
}

.md-stepper span.md-stepper-label, .md-stepper span.md-stepper-description {
  /* color: white; */
}

</style>
