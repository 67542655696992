<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <span class="template_title">商品类目: </span
        >{{ template.categoryList.map(c => c.name).join(' > ') }}
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <h5>类目属性</h5>
        <md-table>
          <md-table-row
            v-for="(item, key) in template.properties"
            v-bind:key="key"
          >
            <md-table-cell>{{ item.name }}</md-table-cell>
            <md-table-cell>{{
              Array.isArray(item.valueText)
                ? item.valueText.join(",")
                : item.valueText
            }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="md-layout-item">
        <h5>规格和价格</h5>
        <md-table>
          <md-table-row
            v-for="item in template.specItems"
            v-bind:key="item.name"
          >
            <md-table-cell>{{ item.name }}</md-table-cell>
            <md-table-cell>{{ item.price }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.template_title {
  font-weight: bold;
}
</style>
