<template>
  <md-field>
    <label>{{ settings.name }}</label>
    <md-input
      v-model="valueText"
      :required="settings.required"
      :md-counter="settings.max_value"
      :maxlength="settings.max_value"
      @input="emitValue"
    ></md-input>
  </md-field>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: false, default: null },
    settings: { type: Object, required: true },
  },
  data: () => ({
    valueText: null,
  }),
  watch: {},
  created() {
    if (this.value) {
      this.valueText = this.value.value;
    }
  },
  methods: {
    emitValue: function (value) {
      this.$emit('input', {
        value,
        id: this.settings.ref_pid,
        name: this.settings.name,
      })
    },
  },
};
</script>

<style>
span.md-count {
  bottom: -9px !important;
}
</style>
