<template>
  <div class="md-layout" v-if="!loading">
    <div
      class="md-layout-item md-size-50"
      v-for="item in filterProperties()"
      :key="item.ref_pid"
    >
      <component
        v-model="values['id_' + item.ref_pid]"
        :is="getControlType(item)"
        :settings="item"
      ></component>
    </div>
  </div>
</template>

<script>
import shopService from "@/store/services/shop-service.js";
import customAutoComplete from "@/pdd/property/autoComplete.vue";
import customSelect from "@/pdd/property/select.vue";
import customTextbox from "@/pdd/property/textbox.vue";
import customDebug from "@/pdd/property/debug.vue";


export default {
  components: {
    customAutoComplete,
    customSelect,
    customTextbox,
    customDebug,
  },
  props: {
    cat_id: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    properties: [], // 属性列表
    values: {}, // 所有的值
    loading: false,
  }),
  watch: {
    cat_id: function (newValue, oldValue) {
      // console.log('aaa')
      this.getProperties().then(() => {
        this.$set(this, "values", {});
        // this.$set(this.values, 'id_' + property.id, null)
      });

      // this.$set(this, 'values', {})
    },
  },
  created() {
    this.loading = true;
    this.getProperties().then(() => {
      // 初始化values值
      this.properties.forEach((property) => {
        // 检查property在value里是否存在，如果存在就用value里的值，否则用默认值
        let item = this.value.find((item) => item.id === property.ref_pid);
        if (item) {
          this.$set(this.values, "id_" + property.ref_pid, item);
          return;
        }

        this.$set(this.values, "id_" + property.ref_pid, null);
      });

      this.loading = false;
    });
  },
  methods: {
    getProperties() {
      // https://open.pinduoduo.com/application/document/api?id=pdd.goods.cat.rule.get
      return shopService.getCategoryProperties(this.cat_id).then((data) => {
        this.properties = data.goods_properties_rule.properties;
      });
    },
    filterProperties() {
      return this.properties.filter(property => {
        if (Array.isArray(property.show_condition) && property.show_condition.length > 0) {

          for (let i = 0; i < property.show_condition.length; i++) {
            let condition = property.show_condition[i];
            let value = this.values['id_' + condition.parent_ref_pid];

            if (!value) {
              this.$set(this.values, "id_" + property.ref_pid, null);
              return false;
            }

            if (!condition.parent_vids.includes(value.valueId)) {
              this.$set(this.values, "id_" + property.ref_pid, null);
              return false;
            }
          }

        }

        return true;
      })
    },
    getControlType(property) {
      // if (
      //   property.control_type == 1 &&
      //   property.choose_max_num == 1 &&
      //   property.values.length >= 30
      // ) {
      //   return "customAutoComplete";
      // }

      if (property.choose_max_num == 1) {
        return "customAutoComplete";
      }

      if (property.choose_max_num > 1) {
        return "customSelect";
      }

      if (property.choose_max_num == 0) {
        return "customTextbox";
      }

      return "customDebug";
    },
  },
  beforeDestroy() {
    this.$set(this, "values", {});
  },
};
</script>

<style>
</style>
