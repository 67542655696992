<template>
  <form class="md-layout" novalidate>
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>info</md-icon>
          </div>
          <h4 class="title">模版信息</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-field :class="getValidationClass('name')">
                <label>模版名称</label>
                <md-input
                  v-model="template.name"
                  required
                  maxlength="100"
                ></md-input>
                <span class="md-error" v-if="!$v.template.name.required"
                  >请输入模版名称！</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>category</md-icon>
          </div>
          <h4 class="title">商品类目</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <pdd-category v-model="cat_1" :class="getVClass('cat_1')">
                <label>类目一</label>
                <span class="md-error" v-if="!$v.cat_1.required"
                  >请选择类目！</span
                >
              </pdd-category>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <pdd-category
                v-if="cat_1 && !cat_1.leaf"
                v-model="cat_2"
                :parent="cat_1"
                :class="getVClass('cat_2')"
              >
                <label>类目二</label>
                <span class="md-error" v-if="!$v.cat_2.required"
                  >请选择类目！</span
                >
              </pdd-category>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <pdd-category
                v-if="cat_2 && !cat_2.leaf"
                v-model="cat_3"
                :parent="cat_2"
                :class="getVClass('cat_3')"
              >
                <label>类目三</label>
                <span class="md-error" v-if="!$v.cat_3.required"
                  >请选择类目！</span
                >
              </pdd-category>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <pdd-category
                v-if="cat_3 && !cat_3.leaf"
                v-model="cat_4"
                :parent="cat_3"
                :class="getVClass('cat_4')"
              >
                <label>类目四</label>
                <span class="md-error" v-if="!$v.cat_4.required"
                  >请选择类目！</span
                >
              </pdd-category>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card v-if="cat_id">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>construction</md-icon>
          </div>
          <h4 class="title">类目属性</h4>
        </md-card-header>
        <md-card-content>
          <properties
            ref="properties"
            :cat_id="cat_id"
            v-model="template.properties"
          ></properties>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>currency_yen</md-icon>
          </div>
          <h4 class="title">规格和价格</h4>
        </md-card-header>
        <md-card-content>
          <div
            class="md-layout"
            v-for="(item, key) in template.specItems"
            v-bind:key="key"
          >
            <div class="md-layout-item md-size-70">
              <md-field :class="getValidationClass('specItems', key, 'name')">
                <label>规格&nbsp;{{ key + 1 }}</label>
                <md-input
                  v-model="item.name"
                  :maxlength="specMaxLength"
                  required
                ></md-input>
                <span
                  class="md-error"
                  v-if="!$v.template.specItems.$each[key].name.required"
                  >请输入规格名称！</span
                >
                <span
                  class="md-error"
                  v-if="!$v.template.specItems.$each[key].name.maxLength"
                  >超长了，不能超过{{specMaxLength}}个字符</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-field :class="getValidationClass('specItems', key, 'price')">
                <label>价格</label>
                <span class="md-prefix">¥</span>
                <md-input
                  type="number"
                  v-model="item.price"
                  required
                ></md-input>
                <span
                  class="md-error"
                  v-if="!$v.template.specItems.$each[key].price.required"
                  >请输入价格！</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-size-10 operation-items">
              <!-- <md-field> -->
              <md-button
                class="md-warning md-just-icon md-round operation-button"
                @click="deleteItem(key)"
              >
                <!-- md-button md-primary md-simple md-theme-default -->
                <md-icon>remove</md-icon>
                <md-tooltip>删除规格&nbsp;{{ key + 1 }}</md-tooltip>
              </md-button>
              <!-- </md-field> -->
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item">
              <md-button
                class="md-success md-just-icon md-round"
                @click="addItem()"
              >
                <!-- md-button md-primary md-simple md-theme-default -->
                <md-icon>add</md-icon>
                <md-tooltip>添加新的规格</md-tooltip>
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>image</md-icon>
          </div>
          <h4 class="title">图片配置</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item custom-images md-size-100">
              <label class="field-label">来图定制</label>
              <file-pond
                name="image"
                ref="customImage"
                label-idle="拖动文件到这里或者点击选择来图定制"
                labelFileProcessingComplete="上传成功"
                labelFileProcessing="上传中"
                labelTapToRetry="点击重试"
                labelTapToUndo="点击撤销"
                labelTapToCancel="点击取消"
                v-bind:allow-multiple="false"
                accepted-file-types="image/jpeg, image/png"
                :server="serverOptions"
                v-on:processfilestart="handleFileStart(1)"
                v-on:addfilestart="handleFileStart(1)"
                v-on:processfiles="handleFileDone(1)"
                credits="false"
                allowReorder="true"
                v-model="customImage"
                v-bind:files="customImage"
              />
            </div>
            <div class="md-layout-item custom-images md-size-100">
              <label class="field-label">详情图顶部</label>
              <file-pond
                name="image"
                ref="detailImagesTop"
                label-idle="拖动文件到这里或者点击选择详情图顶部"
                labelFileProcessingComplete="上传成功"
                labelFileProcessing="上传中"
                labelTapToRetry="点击重试"
                labelTapToUndo="点击撤销"
                labelTapToCancel="点击取消"
                v-bind:allow-multiple="true"
                accepted-file-types="image/jpeg, image/png"
                :server="serverOptions"
                v-on:processfilestart="handleFileStart(2)"
                v-on:addfilestart="handleFileStart(2)"
                v-on:processfiles="handleFileDone(2)"
                credits="false"
                allowReorder="true"
                v-model="detailImagesTop"
                v-bind:files="detailImagesTop"
              />
            </div>
            <div class="md-layout-item custom-images md-size-100">
              <label class="field-label">详情图底部</label>
              <file-pond
                name="image"
                ref="detailImagesBottom"
                label-idle="拖动文件到这里或者点击选择详情图底部"
                labelFileProcessingComplete="上传成功"
                labelFileProcessing="上传中"
                labelTapToRetry="点击重试"
                labelTapToUndo="点击撤销"
                labelTapToCancel="点击取消"
                v-bind:allow-multiple="true"
                accepted-file-types="image/jpeg, image/png"
                :server="serverOptions"
                v-on:processfilestart="handleFileStart(4)"
                v-on:addfilestart="handleFileStart(4)"
                v-on:processfiles="handleFileDone(4)"
                credits="false"
                allowReorder="true"
                v-model="detailImagesBottom"
                v-bind:files="detailImagesBottom"
              />
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout">
      <div class="md-layout-item">
        <h6
          v-if="imageUploading > 0"
          style="margin-block-start: 0; margin-block-end: 0"
        >
          图片上传中... 请稍等
        </h6>
        <md-button
          class="md-primary"
          @click="save"
          :disabled="imageUploading > 0"
          >保存</md-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import boltService from "@/store/services/bolt-service.js";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import pddCategory from "@/pdd/category.vue";
import properties from "@/pdd/properties.vue";
import Category from "@/pdd/PddCategoryObject.js";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Create component
const FilePond = vueFilePond();
// FilePondPluginFileValidateType,
// FilePondPluginImagePreview

export default {
  components: {
    pddCategory,
    properties,
  },
  props: {
    templateId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      template: {
        name: "",
        specItems: [{ name: "", price: "" }],
        properties: null,
      },
      cat_1: null,
      cat_2: null,
      cat_3: null,
      cat_4: null,
      createMode: false,
      customImage: [], //来图定制
      detailImagesTop: [], //详情图顶部
      detailImagesBottom: [], //详情图底部
      imageUploading: 0, // 1: 来图定制 2: 详情图顶部 4: 详情图底部, 二进制占位
    };
  },
  computed: {
    cat_id: function () {
      if (this.cat_4) {
        return this.cat_4.id;
      }

      if (this.cat_3 && this.cat_3.leaf) {
        return this.cat_3.id;
      }

      return null;
    },
    category_list: function () {
      if (this.cat_id) {
        let categoryList = [
          this.cat_1.toJson(),
          this.cat_2.toJson(),
          this.cat_3.toJson(),
        ];

        if (this.cat_4) {
          categoryList.push(this.cat_4.toJson());
        }

        return categoryList;
      }

      return null;
    },
    serverOptions() {
      return {
        headers: {
          Authorization: "bearer " + this.$store.state.app.keycloak.token,
        },
        url: process.env.VUE_APP_ROOT_API + "/bolt/configupload",
      };
    },
    specMaxLength() {
      return process.env.VUE_APP_PDD_SPEC_MAX_LENGHT;
    }
  },
  validations: {
    template: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      specItems: {
        $each: {
          name: {
            required,
            maxLength: maxLength(process.env.VUE_APP_PDD_SPEC_MAX_LENGHT),
          },
          price: {
            required,
          },
        },
      },
    },
    cat_1: {
      required,
    },
    cat_2: {
      required,
    },
    cat_3: {
      required,
    },
    cat_4: {
      required: requiredIf(function () {
        return this.cat_3 && !this.cat_3.leaf;
      }),
    },
  },
  methods: {
    getTemplate() {
      boltService.getProductTemplate(this.templateId).then((template) => {
        this.template = template;
        // this.template = this.$route.params.template;

        for (var i = 0; i < this.template.categoryList.length; i++) {
          let category = this.template.categoryList[i];
          this["cat_" + (i + 1)] = new Category(
            category.id,
            category.name,
            category.leaf
          );
        }

        function mapItem(item) {
          return {
            options: {
              type: "local",
              file: {
                name: item.filename, // The name for the file
                size: item.size,
              },
            },
            source: item.serverId,
          };
        }

        this.customImage = this.template.images?.customImage?.map(mapItem);
        this.detailImagesTop =
          this.template.images?.detailImagesTop?.map(mapItem);
        this.detailImagesBottom =
          this.template.images?.detailImagesBottom?.map(mapItem);

        this.$watch("cat_id", function (newVal, oldVal) {
          this.$set(this.template, "properties", []);
        });
      });
    },
    getVClass(fieldName) {
      let field = this.$v[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
          "md-error": field.$invalid && field.$dirty,
        };
      }
    },
    getValidationClass(fieldName, index, property) {
      let field = this.$v["template"][fieldName];

      if (typeof index !== "undefined") {
        field = field.$each[index][property];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
          "md-error": field.$invalid && field.$dirty,
        };
      }
    },
    handleFileStart: function (type) {
      let uploading = (this.imageUploading & type) !== 0;
      if (!uploading) {
        this.imageUploading += type;
      }
    },
    handleFileDone: function (type) {
      let uploading = (this.imageUploading & type) !== 0;
      if (uploading) {
        this.imageUploading -= type;
      }
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        function imageMap(item) {
          return {
            serverId: item.serverId,
            filename: item.filename,
            size: item.fileSize,
          };
        }

        let templateData = {
          name: this.template.name,
          specItems: this.template.specItems,
          categoryId: this.cat_id,
          categoryList: this.category_list,
          properties: Object.values(this.$refs.properties.values).filter(
            Boolean
          ),
          images: {
            customImage: this.customImage?.map(imageMap),
            detailImagesTop: this.detailImagesTop?.map(imageMap),
            detailImagesBottom: this.detailImagesBottom?.map(imageMap),
          },
        };

        if (this.createMode) {
          boltService.createProductTemplate(templateData).then((template) => {
            this.$store.dispatch("alerts/success", "保存成功");
            this.$emit("template-updated", template.id);
          });
        } else {
          boltService
            .updateProductTemplate(this.templateId, templateData)
            .then(() => {
              this.$store.dispatch("alerts/success", "保存成功");
              this.$emit("template-updated", this.templateId);
            });
        }
      } else {
        this.$store.dispatch("alerts/error", "请检查页面的输入！");
      }
    },
    deleteItem(key) {
      this.template.specItems.splice(key, 1);
      if (this.template.specItems.length == 0) {
        this.template.specItems.push({ name: "", price: "" });
      }
    },
    addItem() {
      this.template.specItems.push({ name: "", price: "" });
    },
    onSelectCategory(item) {},
  },
  created() {
    if (this.templateId == null) {
      this.createMode = true;

      this.$watch("cat_id", function (newVal, oldVal) {
        this.$set(this.template, "properties", []);
      });
    } else {
      this.getTemplate();
    }
  },
};
</script>

<style>
.operation-items {
  position: relative;
}

.md-button.operation-button {
  width: 27px;
  height: 27px;
  min-width: 27px;
  line-height: 27px;
  padding: 5px;
  margin: 0;

  position: absolute;
  bottom: 10px;
}
</style>
