<template>
  <md-select :value="value" @md-selected="emitValue" v-bind="$attrs">
    <md-option v-for="item in options" :key="item.value" :value="item.value">{{
      item.label
    }}</md-option>
  </md-select>
</template>

<script>
import { Select } from '@/mixins/Select.js'

export default {
  extends: Select,

  inheritAttrs: false,

  props: {
    value: {
      type: Number,
      required: false,
    }, //默认值
  },

  methods: {
    getOptions() {
      let options = [2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
        return {
          label: item + '人',
          value: item,
        }
      })

      return Promise.resolve(options)
    },
  },
}
</script>
