<template>
  <form novalidate>
    <div class="md-layout">
      <div class="md-layout-item md-size-60 md-medium-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>info</md-icon>
            </div>
            <md-switch v-model="showTemplateInfo" class="md-primary" style="float:right; margin: 16px 16px 0 0">显示模版信息</md-switch>
            <h4 class="title">选择模版</h4>

          </md-card-header>
          <md-card-content>
            <md-field :class="getValidationClass('productTemplateId')">
              <label>商品模版</label>
              <product-template v-model="productTemplateId" required></product-template>
              <span class="md-error" v-if="!$v.productTemplateId.required">请选择商品模版!</span>
            </md-field>

            <template-information v-if="productTemplate && showTemplateInfo" :template="productTemplate"></template-information>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>settings</md-icon>
            </div>
            <h4 class="title">基础配置</h4></md-card-header
          >
          <md-card-content>
            <md-field :class="getValidationClass('shippingTemplateId')">
              <label>运费模版</label>
              <shipping-template
                v-model="shippingTemplateId" required
              ></shipping-template>
              <span class="md-error" v-if="!$v.shippingTemplateId.required">请选择运费模版!</span>
            </md-field>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>image</md-icon>
            </div>
            <h4 class="title">商品信息</h4>
          </md-card-header>
          <md-card-content>
            <cm-field :class="getValidationClass('title')">
              <label>商品名称</label>
              <md-input v-model="title" required md-counter="60"></md-input>
              <span class="md-error" v-if="!$v.title.required">请输入商品名称！</span>
              <span class="md-error" v-if="!$v.title.maxLength">商品名称不能超过30个汉字或60个英文字符！</span>
            </cm-field>
            <br />
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-50 md-small-size-100 custom-images" :class="getValidationClass('mainImages')">
                <label class="field-label">主图*</label>
                <file-pond
                  name="image"
                  ref="mainImage"
                  label-idle="拖动文件到这里或者点击选择主图上传"
                  labelFileProcessingComplete="上传成功"
                  labelFileProcessing="上传中"
                  labelTapToRetry="点击重试"
                  labelTapToUndo="点击撤销"
                  labelTapToCancel="点击取消"
                  v-bind:allow-multiple="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="serverOptions"
                  v-on:init="handleFilePondInit"
                  v-on:processfilestart="handleFileStart(1)"
                  v-on:addfilestart="handleFileStart(1)"
                  v-on:processfiles="handleFileDone(1)"
                  credits="false"
                  allowReorder="true"
                  maxFiles="10"
                  v-model="mainImages"
                  v-bind:files="mainImages"
                />
                <span class="md-custom-error" v-if="!$v.mainImages.required">请选择商品主图！</span>

              </div>
              <div class="md-layout-item md-medium-size-50 md-small-size-100 custom-images" :class="getValidationClass('detailImages')">
                <label class="field-label">详情图{{ !$v.detailImages.required ? "*" : '' }}</label>
                <file-pond
                  name="image"
                  ref="detailImage"
                  label-idle="拖动文件到这里或者点击选择详情图上传"
                  labelFileProcessingComplete="上传成功"
                  labelFileProcessing="上传中"
                  labelTapToRetry="点击重试"
                  labelTapToUndo="点击撤销"
                  labelTapToCancel="点击取消"
                  v-bind:allow-multiple="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="serverOptions"
                  v-on:init="handleFilePondInit"
                  v-on:processfilestart="handleFileStart(2)"
                  v-on:addfilestart="handleFileStart(2)"
                  v-on:processfiles="handleFileDone(2)"
                  credits="false"
                  allowReorder="true"
                  v-model="detailImages"
                  v-bind:files="detailImages"
                />
                <span class="md-custom-error" v-if="!$v.detailImages.required">请选择商品详情图！</span>
              </div>
              <div class="md-layout-item custom-images" :class="getValidationClass('skuImages')">
                <label class="field-label">SKU图*</label>
                <file-pond
                  name="image"
                  ref="skuImage"
                  label-idle="拖动文件到这里或者点击选择SKU图上传"
                  labelFileProcessingComplete="上传成功"
                  labelFileProcessing="上传中"
                  labelTapToRetry="点击重试"
                  labelTapToUndo="点击撤销"
                  labelTapToCancel="点击取消"
                  v-bind:allow-multiple="true"
                  accepted-file-types="image/jpeg, image/png"
                  :server="serverOptions"
                  v-on:init="handleFilePondInit"
                  v-on:processfilestart="handleFileStart(4)"
                  v-on:addfilestart="handleFileStart(4)"
                  v-on:processfiles="handleFileDone(4)"
                  credits="false"
                  allowReorder="true"
                  v-model="skuImages"
                  v-bind:files="skuImages"
                />
                <span class="md-custom-error" v-if="!$v.skuImages.required">请选择商品SKU图！</span>
              </div>

            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="md-layout">
      <div class="md-layout-item">
        <h6 v-if="imageUploading > 0" style="margin-block-start:0;margin-block-end:0;">图片上传中... 请稍等</h6>
        <md-button class="md-primary" @click="upload" :disabled="imageUploading > 0">上传</md-button>
      </div>
    </div>
    <br />
    <div class="md-layout">
      <div class="md-layout-item">
        <upload-task-list-card ref="taskListCart"></upload-task-list-card>
      </div>
    </div>

  </form>
</template>

<script>
import ShippingTemplate from "@/pdd/ShippingTemplate.vue";
import ProductTemplate from "@/pdd/bolt/ProductTemplate.vue";
import boltService from "../../../store/services/bolt-service";
import { validationMixin } from "vuelidate";
import { required, maxLength, requiredIf, helpers } from "vuelidate/lib/validators";
import UploadTaskListCard from "../User/ShopManagement/UploadTaskListCard.vue";
import TemplateInformation from "@/pdd/bolt/TemplateInformation.vue";

// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
// import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(
  // FilePondPluginFileValidateType,
  // FilePondPluginImagePreview
);

// const IMAGE_UPLOADING_MAIN = 1;
// const IMAGE_UPLOADING_DETAIL = 2;
// const IMAGE_UPLOADING_SKU = 4;

const max_cn_en_length = (maxLength) => helpers.withParams(
  { type: 'max_cn_en_length', value: maxLength },
  function (value) {
    if (!helpers.req(value)) return true; // Ignore empty fields
    let length = 0;
    for (let i = 0; i < value.length; i++) {
      if ((value.charCodeAt(i) >= 0 && value.charCodeAt(i) <= 128))
        length++;
      else
        length += 2;
    }

    return length <= maxLength;
  }
)

export default {
  components: { ShippingTemplate, ProductTemplate, FilePond, UploadTaskListCard, TemplateInformation },
  mixins: [validationMixin],
  data() {
    return {
      shippingTemplateId: null,
      productTemplateId: null,

      productTemplate: null,

      title: null, //商品名称
      mainImages: null, //主图
      detailImages: null, //详情图
      skuImages: null, //SKU图

      showTemplateInfo: false,

      imageUploading: 0, // 1: 主图 2: 详情图 4: SKU图, 二进制占位
    };
  },
  validations: {
    title: {
      required,
      maxLength: max_cn_en_length(60),
    },
    shippingTemplateId: {
      required,
    },
    productTemplateId: {
      required,
    },
    mainImages: {
      required,
    },
    detailImages: {
      required: requiredIf(function() {
        let images = this.productTemplate?.images

        // 模版里没有图片的话，详情图是必须的
        if (!images) {
          return true
        }

        let detailImagesTop = images.detailImagesTop
        let detailImagesBottom = images.detailImagesBottom

        if (detailImagesTop == null) {
          detailImagesTop = []
        }

        if (detailImagesBottom == null) {
          detailImagesBottom = []
        }

        // 详情图上和下 都没有图的话，详情图就是必须的了
        let allImages = detailImagesTop.concat(detailImagesBottom)
        return allImages.length == 0
      })
    },
    skuImages: {
      required
    }

  },
  watch: {
    productTemplateId: {
      handler(val) {
        this.onProductTemplateChange(val);
      },
    },
  },
  computed: {
    serverOptions() {
      return {
        headers: {
          Authorization: "bearer " + this.$store.state.app.keycloak.token,
        },
        url: process.env.VUE_APP_ROOT_API + "/bolt/upload",
      }
    }
  },
  methods: {
    onProductTemplateChange(val) {
      boltService.getProductTemplate(val).then((productTemplate) => {
        this.productTemplate = productTemplate;
      });
    },
    handleFilePondInit: function () {
      // FilePond 初始化
      // FilePond instance methods are available on `this.$refs.pond`
    },
    handleFileStart: function(type) {
      let uploading = (this.imageUploading & type) !== 0;
      if (!uploading) {
        this.imageUploading += type
      }
    },
    handleFileDone: function(type) {
      let uploading = (this.imageUploading & type) !== 0;
      if (uploading) {
        this.imageUploading -= type
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
          "md-error": field.$invalid && field.$dirty,
        };
      }
    },
    upload(e) {
      e.preventDefault();
      this.$v.$touch();

      if (!this.$v.$invalid) {
        boltService.createTask({
            title: this.title,
            config: {
              shippingTemplateId: this.shippingTemplateId,
              productTemplateId: this.productTemplateId,
              mainImages: this.mainImages.map(item => item.serverId),
              detailImages: this.detailImages?.map(item => item.serverId),
              skuImages: this.skuImages.map(item => item.serverId),
            }
          })
          .then(() => {
            this.title = ''
            this.mainImages = null
            this.detailImages = null
            this.skuImages = null
            this.imageUploading = 0;

            this.$refs.taskListCart.getList();
            this.$v.$reset();
            this.$store.dispatch("alerts/success", '任务已经加入排队队列！【任务状态会在下面的记录里自动更新】');
            window.scrollTo({
              top: 0,
              behavior: "smooth",
              left: 0,
            });
          })
          .catch((err) => {
            this.$store.dispatch("alerts/error", err.response.data?.error);
          });
      } else {
        this.$store.dispatch("alerts/error", "请检查页面的输入！");
      }
    }
  },
  mounted() {
    boltService.getSettings().then((settings) => {
      this.shippingTemplateId = settings.shippingTemplateId;
    }).finally(() => {
      this.$watch('shippingTemplateId', (val) => {
        boltService.updateSettings({
          config: {
            shippingTemplateId: val,
          },
        });
      })
    })
  },
};
</script>

<style>
.field-label {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
  top: 26px;
}

.custom-images {
  position: relative;
}

.md-custom-error {
  display: none;
}

.md-invalid .md-custom-error {
  display: block;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  transition: .3s cubic-bezier(.4,0,.2,1);
  color: #ff1744;
  color: var(--md-theme-default-fieldvariant, #ff1744);

}
</style>
