<template>
  <div>
    <md-table
      md-fixed-header
      v-model="members"
      @md-selected="onSelect"
      class="member-table"
      :class="{ 'non-interactive': removeMemberActive }"
    >
      <md-table-toolbar class="member-toolbar">
        <div class="md-toolbar-section-start">
          <h1 class="md-title">成员列表</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-icon>search</md-icon>
          <label>搜索</label>
          <md-input v-model="search" />
        </md-field>
      </md-table-toolbar>

      <md-table-toolbar
        slot="md-table-alternate-header"
        slot-scope="{ count }"
        class="member-alternate-header"
      >
        <div class="md-toolbar-section-start">
          {{ getAlternateLabel(count) }}
        </div>

        <div class="md-toolbar-section-end">
          <md-button
            class="md-icon-button delete-icon md-danger"
            @click="confirmRemove"
          >
            <md-icon>delete</md-icon>
            <md-tooltip>移除成员</md-tooltip>
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="multiple"
        md-auto-select
        :md-disabled="
          item.accountId == $store.getters['mall/current'].account_id
        "
      >
        <md-table-cell md-label="店铺">
          <md-avatar>
            <img :src="item.logo" alt="avatar" />
          </md-avatar>
          {{ item.name }}
        </md-table-cell>

        <md-table-cell md-label="加入时间">{{
          item.created_at | moment
        }}</md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog-confirm
      :md-active.sync="removeMemberActive"
      md-title="确定要移除这些成员吗?"
      :md-content="
        '当前您正要移除' + selected.length + '个成员，移除后将无法恢复。'
      "
      md-confirm-text="确定"
      md-cancel-text="取消"
      @md-confirm="onConfirmRemove"
      @md-cancel="onCancelRemove"
    />
  </div>
</template>

<script>
import groupService from "../../../store/services/group-service";
import debounce from "lodash/debounce";
import moment from "moment";

export default {
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      members: [],
      search: "",
      originalMembers: [],
      selected: {},
      removeMemberActive: false,
    };
  },
  watch: {
    groupId() {
      this.getMembers();
    },
    search: debounce(function () {
      if (!this.search) {
        this.members = this.originalMembers;
        return;
      }

      // Call API to search members
      this.members = this.originalMembers.filter((member) =>
        member.name.includes(this.search)
      );
    }, 200),
  },
  mounted() {
    this.getMembers();
  },
  filters: {
    moment: function (datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    async getMembers() {
      // Call API to get members
      this.members = await groupService.getGroupMember(this.groupId);

      // let data = this.members[0];
      // // 模拟超过30个members的情况，把members里的数据进行重复
      // for (let i = 0; i < 30; i++) {
      //   data.accountId = i;
      //   this.members.push(data);
      // }

      this.originalMembers = this.members;
    },
    onSelect(items) {
      this.selected = items;
    },
    getAlternateLabel(count) {
      return `您选择了 ${count} 个成员`;
    },
    confirmRemove() {
      this.removeMemberActive = true;
    },
    onConfirmRemove() {
      groupService
        .removeMembers(
          this.groupId,
          this.selected.map((item) => item.accountId)
        )
        .then(() => {
          this.getMembers();
          this.$store.dispatch("alerts/success", "移除成功。");
          this.search = "";
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err.message);
        });

      this.removeMemberActive = false;
    },
    onCancelRemove() {
      this.removeMemberActive = false;
    },
  },
};
</script>

<style>
.member-table .md-table-alternate-header {
  margin: 0 10px;
  /* background-color: red !important; */
}
</style>
<style scoped>
.member-toolbar {
  padding: 0;
}

button.delete-icon {
  width: 40px;
  height: 40px;
}

.md-title {
  font-weight: bold;
}

.non-interactive {
  pointer-events: none;
  opacity: 0.1;
}
</style>
