<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="card-icon">
        <md-icon>task_alt</md-icon>
      </div>
      <h4 class="title">搬家记录</h4>
    </md-card-header>
    <md-card-content>
      <md-table
        :value="table"
        :md-sort.sync="sortation.field"
        :md-sort-order.sync="sortation.order"
        :md-sort-fn="customSort"
        class="paginated-table table-striped table-hover"
      >
        <md-table-toolbar>
          <div class="md-layout">
            <div class="md-layout-item"></div>
            <div class="md-layout-item"></div>

            <div class="md-layout-item align-right">
              <md-button
                class="md-success md-just-icon"
                @click="refresh()"
              >
                <md-icon>refresh</md-icon>
                <md-tooltip md-direction="bottom">刷新列表 <br /><small>系统每15秒会自动刷新列表状态</small></md-tooltip>
              </md-button>
            </div>

            <div class="md-layout-item">
              <div class="md-toolbar-section-start"></div>
              <md-field class="md-toolbar-section-end">
                <label>每页显示条数</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="任务创建时间">{{
            item.createdAt | moment
          }}</md-table-cell>
          <md-table-cell md-label="原地址"
            ><a target="_blank" :href="item.url" class="target_url">{{
              item.url
            }}</a></md-table-cell
          >
          <md-table-cell md-label="状态">
            <span v-if="item.status == '错误'">
              {{ item.status }} <md-icon class="error md-accent">error</md-icon>
              <md-tooltip md-direction="top">{{ item.error }}</md-tooltip>
            </span>

            <span v-else>{{ item.status }} </span></md-table-cell
          >
          <md-table-cell md-label="商品名称">{{ item.title }}</md-table-cell>

          <md-table-cell md-label="操作">
            <a :href="downloadUrl(item)" v-if="item.status === '已完成' && item.hasMedia">
              <md-icon>download</md-icon><md-tooltip md-direction="bottom">下载商品图片</md-tooltip>
            </a>
            <a v-if="item.externalId" :href="getLink(item)" target="_blank"
              ><md-icon>search</md-icon>查看商品</a
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div v-if="total > 0">
        <p class="card-category">
          当前 {{ from + 1 }} 到 {{ to }}， 共 {{ total }} 条记录
        </p>
      </div>
      <div v-if="total == 0">
        <p class="card-category">暂无记录</p>
      </div>
      <pagination
        v-if="total > 0"
        class="pagination-no-border pagination-success"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </md-card-actions>
  </md-card>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    timer: null, //定时自动刷新
    table: [],
    // footerTable: ['Name', 'Email', 'Created At', 'Actions'],

    query: null,

    sortation: {
      field: "created_at",
      order: "asc",
    },

    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: 0,
  }),
  watch: {
    pagination: {
      deep: true,
      handler: function (newValue) {
        this.getList();
      },
    },
  },

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    this.getList();
    this.startAutoUpdate();
  },

  filters: {
    moment: function (dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    async getList() {
      let response = await this.$http.get("/histories", {
        params: {
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        },
      });

      this.total = response.data.pagination.rowCount;
      this.table = response.data.items;
    },

    async refresh() {
      await this.getList()
      this.$store.dispatch("alerts/success", '刷新完成');
    },

    customSort() {
      return false;
    },

    onCancel() {},

    startAutoUpdate() {
      this.timer = setInterval(this.getList, 15000);
    },
    cancelAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    getLink(item) {
      return `https://mms.pinduoduo.com/goods/goods_add/index?id=${item.externalCommitId}&goods_id=${item.externalId}&type=edit`;
    },
    downloadUrl(item) {
      return (
        process.env.VUE_APP_ROOT_API +
        '/histories/' + item.id + '/medias?t=' +
        this.$store.state.app.keycloak.idToken
      )
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style>
.target_url {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.md-dialog-content p {
  display: inline-block;
  max-width: 500px;
  word-break: break-all;
}

.align-right {
  text-align: right;
}
</style>
