<template>
  <div>
    <task-list-card ref="taskListCart"></task-list-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import TaskListCard from "./TaskListCard.vue";

export default {
  mixins: [validationMixin],
  data() {
    return {};
  },

  components: { TaskListCard },
  methods: {},
};
</script>

<style lang="scss">
</style>
