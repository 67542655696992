import store from '../store'

export default function hasPermission({ next, router, to }) {
  let keycloak = store.state.app.keycloak

  if (to.meta?.permission && !keycloak.hasResourceRole(to.meta.permission)) {
    return router.push({ name: '工作台' })
  }

  next()
}
