import axios from "../../axios/index";

// 申请入群
function getGroupMember(groupId) {
  return axios
    .get(`/groups/${groupId}/members`)
    .then((response) => response.data);
}

// 移除成员
function removeMembers(groupId, memberIds) {
  return axios
    .delete(`/groups/${groupId}/members`, { data: {ids: memberIds }})
    .then((response) => response.data);
}

export default {
  getGroupMember,
  removeMembers,
};
