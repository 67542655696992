import Vue from 'vue'

// Lib imports
import axios from 'axios'
import store from '@/store'

axios.defaults.withCredentials = true

axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.VUE_APP_ROOT_API
    // config.withCredentials = true; // 允许携带token ,这个是解决跨域产生的相关问题
    config.timeout = 6000
    config.headers['Content-Type'] = 'application/json'

    const keycloak = store.state.app.keycloak

    if (keycloak.idToken) {
      // 附加token
      config.headers = {
        Authorization: 'bearer ' + keycloak.token
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.code === 'ECONNABORTED') {
      return store.dispatch('app/logout', null, { root: true })
    }

    if (error.code === 'ERR_NETWORK') {
      return store.dispatch("alerts/error", '网络错误，请稍候再试.')
        .then(() => {
          return new Promise((resolve)=> {
            // 延迟5秒
            setTimeout(()=>{
              resolve()
            }, 5000)
          })

        }).then(() => {
          return store.dispatch('app/logout', {
            redirectUri: 'https://whaleniu.cn'
          }, { root: true })
        })
    }

    // 401 未授权 或者 token 过期
    if (error.response?.status === 401) {
      return store.dispatch('app/logout', null, { root: true })
    }

    return Promise.reject(error)
  }
)

Vue.prototype.$http = axios
Vue.$http = axios

export default axios
