<template>
  <shop-profile-card :mall="shopProfile"></shop-profile-card>
</template>

<script>
import ShopProfileCard from './ShopProfileCard.vue'

export default {
  components: { ShopProfileCard },
  data() {
    return {
      shopProfile: null
    }
  },
  methods: {
    async loadShopProfile() {
      // Get shop object from router
      let response = await this.$http.get("/admin/shop/" + this.$route.params.id);
      this.shopProfile = response.data;
    }
  },
  mounted() {
    this.loadShopProfile()

  },
}
</script>

<style>

</style>
