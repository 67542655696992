<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <templates-card ref="templates"
           @selected="onSelected"
           @deleted="onDeleted"
           @create-new-template="onCreateNewTemplate"></templates-card>
      </div>
    </div>
    <template-form-card v-if="showForm" :templateId="selected.id" :key="selected.id"
      @template-updated="onUpdated"

      ></template-form-card>
    <template-form-card v-if="create" @template-updated="onUpdated"></template-form-card>
  </div>
</template>

<script>
import TemplatesCard from "@/pages/Dashboard/User/ShopManagement/TemplatesCard.vue";
import TemplateFormCard from "@/pages/Dashboard/User/ShopManagement/TemplateFormCard.vue"

export default {
  components: {
    TemplatesCard,
    TemplateFormCard,
    // FormCard,
  },
  data: () => ({
    create: false,
    selected: false,
  }),
  watch: {
    create: function(newVal, oldVal) {
      // console.log(newVal, oldVal)
    }
  },
  computed:{
    showForm() {
      if (Array.isArray(this.selected) && this.selected.length > 1) {
        return true
      }

      if (this.selected && this.selected.id) {
        return true
      }

      return false
    }
  },
  methods: {
    onSelected(item) {
      this.selected = item;

      if (Array.isArray(item) && item.length > 1) {
        this.create = false;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            left: 0,
          });

        return
      }

      if (item && item.id) {
        this.create = false;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            left: 0,
          });

        return
      }
    },
    onUpdated() {
      this.$refs.templates.getList(true)
      this.selected = null
      this.create = false
    },
    onCreateNewTemplate() {
      this.create = true
    },
    onDeleted() {
      this.selected = null
      this.create = false
    }
  },
};
</script>

<style>
</style>
